import { Box, Breadcrumbs, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

// helper
import { checkPermission } from "../../../helper/PermissionHelper";
import { pageName } from "../../../reducers/commonSlice";
import { user } from "../../../reducers/userSlice";

// components
import ActionFooter from "../../../components/ActionFooter";
import FullScreenLoading from "../../../components/FullScreenLoading";
import MemberScenesInfo from "../../../components/member/MemberScenesInfo";

// api
import { MemberApi } from "../../../api/MemberApi";

export default function MemberCollectionsEdit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [memberScene, setMemberScene] = useState({
    memberId: null,
    collection: null,
    backdrop: null,
    effect: null,
    stage: null,
    state: {
      key: 1,
      name: "active",
      value: "active",
    },
  });
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [collections, setCollections] = useState([]);
  const [collectionPage, setCollectionPage] = useState(1);
  const [collectionPageSize, setCollectionPageSize] = useState(4);
  const [collectionTotal, setCollectionTotal] = useState(0);
  const [backdrops, setBackdrops] = useState([]);
  const [backdropPage, setBackdropPage] = useState(1);
  const [backdropPageSize, setBackdropPageSize] = useState(4);
  const [backdropTotal, setBackdropTotal] = useState(0);
  const [effects, setEffects] = useState([]);
  const [effectPage, setEffectPage] = useState(1);
  const [effectPageSize, setEffectPageSize] = useState(4);
  const [effectTotal, setEffectTotal] = useState(0);
  const [stages, setStages] = useState([]);
  const [stagePage, setStagePage] = useState(1);
  const [stagePageSize, setStagePageSize] = useState(4);
  const [stageTotal, setStageTotal] = useState(0);
  const [haveDeletePermission, setHaveDeletePermission] = useState(false);
  const [memberId, setMemberId] = useState(null);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);

  useEffect(() => {
    if (page) {
      fetchData();
    }

    if (collectionPage && memberId) {
      const filter = [
        { field: "member_id", value: memberId, op: "eq" },
        { field: "custom_title", value: "", op: "gt" },
      ];
      fetchCollectionsData(filter);
    }

    if (backdropPage && memberId) {
      const filter = [{ field: "member_id", value: memberId, op: "eq" }];
      fetchBackdropsData(filter);
    }

    if (effectPage && memberId) {
      const filter = [{ field: "member_id", value: memberId, op: "eq" }];
      fetchEffectsData(filter);
    }

    if (stagePage && memberId) {
      const filter = [{ field: "member_id", value: memberId, op: "eq" }];
      fetchStagesData(filter);
    }
  }, [page, collectionPage, backdropPage, effectPage, stagePage, memberId]);

  useEffect(() => {
    const deletePermission = checkPermission(currentUser, currentPage, "delete");

    setHaveDeletePermission(deletePermission);
  }, [currentUser, currentPage]);

  const fetchData = async () => {
    try {
      setFullScreenLoading(true);
      const response = await MemberApi.memberScenesDetails(id);
      setFullScreenLoading(false);
      setMemberScene(response);
      setMemberId(response.memberId.id);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const fetchCollectionsData = async filter => {
    try {
      setFullScreenLoading(true);
      const response = await MemberApi.memberCollectionsList(
        collectionPage,
        collectionPageSize,
        filter,
      );
      setFullScreenLoading(false);
      setCollections(response.data);
      setCollectionTotal(response.total);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const fetchBackdropsData = async filter => {
    try {
      setFullScreenLoading(true);
      const response = await MemberApi.memberBackdropsList(backdropPage, backdropPageSize, filter);
      setFullScreenLoading(false);
      setBackdrops(response.data);
      setBackdropTotal(response.total);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const fetchEffectsData = async filter => {
    try {
      setFullScreenLoading(true);
      const response = await MemberApi.memberEffectsList(effectPage, effectPageSize, filter);
      setFullScreenLoading(false);
      setEffects(response.data);
      setEffectTotal(response.total);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const fetchStagesData = async filter => {
    try {
      setFullScreenLoading(true);
      const response = await MemberApi.memberStagesList(stagePage, stagePageSize, filter);
      setFullScreenLoading(false);
      setStages(response.data);
      setStageTotal(response.total);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const validateForm = () => {
    let error = false;

    return error;
  };

  const update = async () => {
    try {
      let error = validateForm();

      if (error) {
        return;
      }

      setFullScreenLoading(true);
      const success = await MemberApi.memberScenesUpdate(id, memberScene);
      if (success) {
        toast.success(t("layout.updated_successfully"));
        navigate(`/members/${memberId}`);
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const deleteMemberScenes = async () => {
    try {
      setFullScreenLoading(true);
      const success = await MemberApi.deleteMemberScenes(id);
      if (success) {
        toast.success(t("layout.deleted_successfully"));
        navigate(`/members/${memberId}`);
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const setNull = target => {
    setMemberScene({ ...memberScene, [target]: null });
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/members"}>{t("menu.account")}</Link>
        <Link to={`/members/${memberScene?.memberId?.id}`}>{t("menu.members")}</Link>
        <Typography color="text.primary">{t("layout.edit")}</Typography>
      </Breadcrumbs>
      <Box component="form">
        <MemberScenesInfo
          memberScene={memberScene}
          setMemberScene={setMemberScene}
          deleteMemberScenes={deleteMemberScenes}
          haveDeletePermission={haveDeletePermission}
          collections={collections}
          collectionPage={collectionPage}
          collectionPageSize={collectionPageSize}
          collectionTotal={collectionTotal}
          setCollectionPage={setCollectionPage}
          backdrops={backdrops}
          backdropPage={backdropPage}
          backdropPageSize={backdropPageSize}
          backdropTotal={backdropTotal}
          setBackdropPage={setBackdropPage}
          effects={effects}
          effectPage={effectPage}
          effectPageSize={effectPageSize}
          effectTotal={effectTotal}
          setEffectPage={setEffectPage}
          stages={stages}
          stagePage={stagePage}
          stagePageSize={stagePageSize}
          stageTotal={stageTotal}
          setStagePage={setStagePage}
          setNull={setNull}
        />
        <Box sx={{ my: 8 }} />
        <ActionFooter onSubmit={update} onCancel={onCancel} />
      </Box>
    </Container>
  );
}
