import { Box, Breadcrumbs, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

// helper
import { checkPermission } from "../../helper/PermissionHelper";
import { pageName } from "../../reducers/commonSlice";
import { user } from "../../reducers/userSlice";

// components
import ActionFooter from "../../components/ActionFooter";
import FullScreenLoading from "../../components/FullScreenLoading";
import RedeemCodeInfo from "../../components/redeemCode/RedeemCodeInfo";

// api
import { BackdropApi } from "../../api/BackdropApi";
import { EffectsApi } from "../../api/EffectsApi";
import { RedeemCodeApi } from "../../api/RedeemCodeApi";
import { StageApi } from "../../api/StageApi";

export default function RedeemCodeEdit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({
    id: null,
    code: "",
    expireAt: "",
    limit: null,
    gifts: {
      points: 0,
      collectionSlot: 0,
      scenesSlot: 0,
      backdrops: [],
      effects: [],
      stages: [],
    },
    reuse: {
      key: 2,
      name: "unreuse",
      value: 0,
    },
    state: {
      key: 1,
      name: "active",
      value: "active",
    },
    remark: "",
  });
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const [haveDeletePermission, setHaveDeletePermission] = useState(false);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);
  const [backdrops, setBackdrops] = useState([]);
  const [backdropPage, setBackdropPage] = useState(1);
  const [backdropPageSize, setBackdropPageSize] = useState(10);
  const [backdropFilter, setBackdropFilter] = useState([]);
  const [backdropTotal, setBackdropTotal] = useState(0);
  const [effects, setEffects] = useState([]);
  const [effectPage, setEffectPage] = useState(1);
  const [effectPageSize, setEffectPageSize] = useState(10);
  const [effectFilter, setEffectFilter] = useState([]);
  const [effectTotal, setEffectTotal] = useState(0);
  const [stages, setStages] = useState([]);
  const [stagePage, setStagePage] = useState(1);
  const [stagePageSize, setStagePageSize] = useState(10);
  const [stageFilter, setStageFilter] = useState([]);
  const [stageTotal, setStageTotal] = useState(0);

  useEffect(() => {
    if (!id) {
      navigate(-1);
    }
    fetchData();
    fetchBackdrops();
    fetchEffects();
    fetchStages();
  }, []);

  useEffect(() => {
    const deletePermission = checkPermission(currentUser, currentPage, "delete");

    setHaveDeletePermission(deletePermission);
  }, [currentUser, currentPage]);

  const fetchData = async () => {
    try {
      setFullScreenLoading(true);
      const response = await RedeemCodeApi.details(id);
      setData(response);
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const fetchBackdrops = async () => {
    try {
      setFullScreenLoading(true);
      const response = await BackdropApi.list(backdropPage, backdropPageSize, backdropFilter);
      setFullScreenLoading(false);
      setBackdrops(response.data);
      setBackdropTotal(response.total);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const fetchEffects = async () => {
    try {
      setFullScreenLoading(true);
      const response = await EffectsApi.list(effectPage, effectPageSize, effectFilter);
      setFullScreenLoading(false);
      setEffects(response.data);
      setEffectTotal(response.total);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const fetchStages = async () => {
    try {
      setFullScreenLoading(true);
      const response = await StageApi.list(stagePage, stagePageSize, stageFilter);
      setFullScreenLoading(false);
      setStages(response.data);
      setStageTotal(response.total);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const validateForm = () => {
    let error = false;

    if (data.code.length == 0) {
      toast.error(t("error.code_is_required"));
      error = true;
    }

    if (data.expireAt.length == 0) {
      toast.error(t("error.expire_at_is_required"));
      error = true;
    }

    return error;
  };

  const update = async () => {
    try {
      setFullScreenLoading(true);

      let error = validateForm();

      if (error) {
        return;
      }

      if (data.limit <= 0) {
        data.limit = null;
      }

      const success = await RedeemCodeApi.update(id, data);
      if (success) {
        toast.success(t("layout.updated_successfully"));
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const deleteData = async () => {
    try {
      setFullScreenLoading(true);
      const success = await RedeemCodeApi.deleteData(id);
      if (success) {
        toast.success(t("layout.deleted_successfully"));
        navigate(`/redeem-codes`);
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/redeem-codes"}>{t("menu.redeem_code")}</Link>
        <Typography color="text.primary">{t("layout.edit")}</Typography>
      </Breadcrumbs>
      <Box component="form">
        <RedeemCodeInfo
          data={data}
          setData={setData}
          backdrops={backdrops}
          backdropPage={backdropPage}
          backdropPageSize={backdropPageSize}
          backdropTotal={backdropTotal}
          setBackdropPage={setBackdropPage}
          effects={effects}
          effectPage={effectPage}
          effectPageSize={effectPageSize}
          effectTotal={effectTotal}
          setEffectPage={setEffectPage}
          stages={stages}
          stagePage={stagePage}
          stagePageSize={stagePageSize}
          stageTotal={stageTotal}
          setStagePage={setStagePage}
          deleteData={deleteData}
          haveDeletePermission={haveDeletePermission}
        />
        <Box sx={{ my: 8 }} />
        <ActionFooter onSubmit={update} onCancel={onCancel} />
      </Box>
    </Container>
  );
}
