import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ReactSwal from "../../helper/AlertHelper";
import { maxUploadFileMb, statusList } from "../../helper/GlobalValueHelper";
import { getPlaceHolderImage, resizeFile } from "../../helper/UnitsHelper";
import DebugLogger from "../DebugLogger";
import ImageUploader from "../ImageUploader";
import UpdaterInfo from "../UpdaterInfo";
// styles
import palette from "../../styles/theme/palette";

export default function MemberCollectionsInfo({
  collections,
  memberCollection,
  setMemberCollection,
  deleteMemberCollections,
  haveDeletePermission,
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [images, setImages] = useState([]);
  const [showImageUploader, setShowImageUploader] = useState(false);

  useEffect(() => {
    if (!memberCollection) return;
    let imageLength = memberCollection.customCoverImage?.length;
    imageLength += memberCollection.uploadFiles?.length || 0;

    let variantLength = 1;

    if (imageLength >= variantLength) {
      setShowImageUploader(false);
    } else {
      setShowImageUploader(true);
    }
  }, [memberCollection]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showDeleteConfirm = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        deleteMemberCollections();
      }
    });
  };

  const onImageChange = async images => {
    let uploadFiles = memberCollection.uploadFiles || [];

    // check if file exists
    let fileExists = false;
    uploadFiles.forEach(file => {
      images.forEach(image => {
        if (file.name === image.file.name) {
          fileExists = true;
          return;
        }
      });
    });

    if (fileExists) {
      return;
    }

    if (images && images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        let extension = image.file.name.split(".").pop().toUpperCase();
        if (extension === "JPG") extension = "JPEG";
        if (image.file.size > 1024 * 1024 * maxUploadFileMb) {
          toast.error(`Image ${i + 1} file size must be less than ${maxUploadFileMb}MB`);
          return;
        }

        const resizedImage = await resizeFile({
          file: image.file,
          extension: extension
        });

        uploadFiles.push(resizedImage);
      }
    }
    setMemberCollection({ ...memberCollection, uploadFiles: uploadFiles });
    images = null;
  };

  const onRemoveImage = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        setMemberCollection({ ...memberCollection, customCoverImage: "" });
      }
    });
  };

  const removeUploadFile = index => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        let newUploadFiles = memberCollection.uploadFiles;
        newUploadFiles.splice(index, 1);
        setMemberCollection({
          ...memberCollection,
          uploadFiles: newUploadFiles,
        });
      }
    });
  };

  const onFileChange = async e => {
    let file = e.target.files[0];
    const fileUrl = URL.createObjectURL(file);
    const size = file.size;

    if (size > 1024 * 1024 * maxUploadFileMb) {
      toast.error(`Asset size must be less than ${maxUploadFileMb}MB`);
      return;
    }

    setMemberCollection({ ...memberCollection, uploadAssetFiles: [file], mediaSrc: fileUrl });
  };

  const onRemoveAssetFile = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        setMemberCollection({
          ...memberCollection,
          uploadAssetFiles: undefined,
          mediaSrc: "",
        });
      }
    });
  };

  const handleCheckboxChange = image => {
    setMemberCollection({
      ...memberCollection,
      refItem: image.id,
      mediaSrc: image.mediaSrc,
      customCoverImage: image.coverImage,
      customTitle: image.title,
      customDesc: image.description,
      uploadFiles: undefined,
    });
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={
          memberCollection.id
            ? t("member.edit_member_collections")
            : t("member.new_member_collections")
        }
        action={
          deleteMemberCollections && haveDeletePermission ? (
            <Box>
              <IconButton onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={showDeleteConfirm}>
                  <Translation>
                    {t => <Typography color={"error"}>{t("layout.delete")}</Typography>}
                  </Translation>
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <Box />
          )
        }
      />

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DebugLogger title={"Member Collection"} data={memberCollection} hidden={true} />
            <DebugLogger title={"Collections"} data={collections} hidden={true} />
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: 2,
              }}
            >
              {collections &&
                collections.length > 0 &&
                collections.map((image, index) => (
                  <Box
                    key={index}
                    component="label"
                    sx={{
                      position: "relative",
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      p: 2,
                      borderRadius: 1,
                      border:
                        memberCollection.refItem == image.id
                          ? `1px solid ${palette.success.main}`
                          : `1px solid #e0e0e0`,
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        display: "flex",
                      }}
                    >
                      <Box
                        component="img"
                        src={
                          image.coverImage.length === 0 ? getPlaceHolderImage() : image.coverImage
                        }
                        sx={{
                          objectFit: "contain",
                          maxWidth: "100%",
                          maxHeight: "100%",
                        }}
                        onLoad={e => {
                          const img = e.target;
                          const { naturalWidth, naturalHeight } = img;
                          const imgAspectRatio = naturalWidth / naturalHeight;

                          let width, height;

                          if (imgAspectRatio > 1) {
                            width = 300 * imgAspectRatio;
                            height = 300;
                          } else {
                            width = 300;
                            height = 300 / imgAspectRatio;
                          }

                          img.style.width = width + "px";
                          img.style.height = height + "px";
                        }}
                      />
                    </Box>
                    <Box sx={{ textAlign: "center", mt: 1 }}>
                      <Typography>{image.title}</Typography>
                    </Box>
                    <Stack direction={"row"} justifyContent={"center"}>
                      <Checkbox
                        checked={memberCollection.refItem == image.id}
                        onChange={e => handleCheckboxChange(image)}
                      />
                    </Stack>
                  </Box>
                ))}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography
              sx={{
                pb: 2,
              }}
            >
              {t("layout.cover_image")}
            </Typography>

            <Box
              sx={{
                mb: 2,
                fontWight: "bold",
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography variant="caption">
                {t("page.maximum_upload_size")}: {maxUploadFileMb} MB
              </Typography>
              <Typography variant="caption">
                {t("page.accept_file_type")}: JPEG / JPG / PNG
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              {memberCollection.customCoverImage.length > 0 && (
                <Grid item xs={12}>
                  <Stack>
                    <Box
                      component={"img"}
                      src={memberCollection.customCoverImage}
                      sx={{
                        objectFit: "contain",
                        maxWidth: "100%",
                        maxHeight: "200px",
                      }}
                    />
                    <Button
                      sx={{
                        p: 2,
                      }}
                      onClick={onRemoveImage}
                    >
                      {t("layout.remove")}
                    </Button>
                  </Stack>
                </Grid>
              )}

              {memberCollection.uploadFiles &&
                memberCollection.uploadFiles.length > 0 &&
                memberCollection.uploadFiles.map((file, index) => (
                  <Grid item xs={12} key={index}>
                    <Stack>
                      <Box
                        component={"img"}
                        src={URL.createObjectURL(file)}
                        sx={{
                          objectFit: "contain",
                          maxWidth: "100%",
                          maxHeight: "200px",
                        }}
                      />
                      <Button
                        sx={{
                          p: 2,
                        }}
                        onClick={() => {
                          removeUploadFile(index);
                        }}
                      >
                        {t("layout.remove")}
                      </Button>
                    </Stack>
                  </Grid>
                ))}
            </Grid>
            {showImageUploader && (
              <ImageUploader
                acceptType={["jpg", "jpeg", "png"]}
                maxNumber={1}
                images={images}
                maxFileSize={1024 * 1024 * maxUploadFileMb}
                onImageChange={onImageChange}
                onRemoveImage={onRemoveImage}
                removeAllButton={false}
              />
            )}
          </Grid>

          <Grid item xs={12}>
            <Typography
              sx={{
                pb: 2,
              }}
            >
              {t("collection.asset")}
            </Typography>

            <Box
              sx={{
                mb: 2,
                fontWight: "bold",
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography variant="caption">
                {t("page.maximum_upload_size")}: {maxUploadFileMb} MB
              </Typography>
              <Typography variant="caption">{t("page.accept_file_type")}: GLB / GITF</Typography>
            </Box>
          </Grid>

          {memberCollection.mediaSrc || memberCollection.uploadAssetFiles ? (
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                component={"img"}
                src={"/assets/icons/glb-logo.webp"}
                maxHeight={200}
                maxWidth={"100%"}
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.open(memberCollection.mediaSrc, "_blank");
                }}
              />

              <Button
                sx={{
                  p: 2,
                }}
                onClick={onRemoveAssetFile}
              >
                {t("layout.remove")}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Button
                variant="contained"
                component="label"
                fullWidth
                sx={{
                  p: 2,
                  mb: 2,
                }}
              >
                {t("collection.click_to_upload_asset_file")}
                <Box
                  component={"input"}
                  type="file"
                  onChange={onFileChange}
                  sx={{
                    display: "none",
                  }}
                  accept=".glb,.gltf"
                />
              </Button>
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <TextField
              value={memberCollection.customTitle}
              onChange={event =>
                setMemberCollection({ ...memberCollection, customTitle: event.target.value })
              }
              fullWidth
              autoFocus
              label={t("member.collections_custom_title")}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Autocomplete
              options={statusList}
              getOptionLabel={option => {
                return t(`member.collections_${option.name}`);
              }}
              value={memberCollection.state}
              filterSelectedOptions
              isOptionEqualToValue={(opt, value) => {
                return opt.key === value.key;
              }}
              onChange={(event, newValue) => {
                setMemberCollection({ ...memberCollection, state: newValue });
              }}
              renderInput={params => <TextField {...params} label={t("layout.state")} />}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t("member.collections_custom_description")}
              multiline
              value={memberCollection.customDesc}
              onChange={event => {
                setMemberCollection({ ...memberCollection, customDesc: event.target.value });
              }}
              rows={4}
            />
          </Grid>

          <UpdaterInfo data={memberCollection} />
        </Grid>
      </CardContent>

      <CardActions sx={{ justifyContent: "space-between", px: 2 }}></CardActions>
    </Card>
  );
}
