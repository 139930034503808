import AuthProvider from "../components/AuthProvider";
import DashboardLayout from "../components/DashboardLayout";
import { getMemberPageName } from "../helper/GlobalValueHelper";
import Create from "../views/member/Create";
import Edit from "../views/member/Edit";
import List from "../views/member/List";
import EffectCreate from "../views/member/effect/Create";
import EffectEdit from "../views/member/effect/Edit";
import StageCreate from "../views/member/stage/Create";
import StageEdit from "../views/member/stage/Edit";
import CollectionCreate from "../views/member/collection/Create";
import CollectionEdit from "../views/member/collection/Edit";
import BackdropCreate from "../views/member/backdrop/Create";
import BackdropEdit from "../views/member/backdrop/Edit";
import SceneCreate from "../views/member/scene/Create";
import SceneEdit from "../views/member/scene/Edit";
import RedeemedCreate from "../views/member/redeem/Create";
import RedeemedEdit from "../views/member/redeem/Edit";

const MemberRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/members",
      element: (
        <AuthProvider page={getMemberPageName()} action={"view"}>
          <List />
        </AuthProvider>
      ),
    },
    {
      path: "/members/:id",
      element: (
        <AuthProvider page={getMemberPageName()} action={"update"}>
          <Edit />
        </AuthProvider>
      ),
    },
    {
      path: "/members/create",
      element: (
        <AuthProvider page={getMemberPageName()} action={"create"}>
          <Create />
        </AuthProvider>
      ),
    },
    {
      path: "/members/effects/create/:id",
      element: (
        <AuthProvider page={getMemberPageName()} action={"create"}>
          <EffectCreate />
        </AuthProvider>
      ),
    },
    {
      path: "/members/effects/:id",
      element: (
        <AuthProvider page={getMemberPageName()} action={"update"}>
          <EffectEdit />
        </AuthProvider>
      ),
    },
    {
      path: "/members/stages/create/:id",
      element: (
        <AuthProvider page={getMemberPageName()} action={"create"}>
          <StageCreate />
        </AuthProvider>
      ),
    },
    {
      path: "/members/stages/:id",
      element: (
        <AuthProvider page={getMemberPageName()} action={"update"}>
          <StageEdit />
        </AuthProvider>
      ),
    },
    {
      path: "/members/collections/:id",
      element: (
        <AuthProvider page={getMemberPageName()} action={"update"}>
          <CollectionEdit />
        </AuthProvider>
      ),
    },
    {
      path: "/members/collections/create/:id",
      element: (
        <AuthProvider page={getMemberPageName()} action={"create"}>
          <CollectionCreate />
        </AuthProvider>
      ),
    },
    {
      path: "/members/backdrops/create/:id",
      element: (
        <AuthProvider page={getMemberPageName()} action={"create"}>
          <BackdropCreate />
        </AuthProvider>
      ),
    },
    {
      path: "/members/backdrops/:id",
      element: (
        <AuthProvider page={getMemberPageName()} action={"update"}>
          <BackdropEdit />
        </AuthProvider>
      ),
    },
    {
      path: "/members/scenes/:id",
      element: (
        <AuthProvider page={getMemberPageName()} action={"update"}>
          <SceneEdit />
        </AuthProvider>
      ),
    },
    {
      path: "/members/scenes/create/:id",
      element: (
        <AuthProvider page={getMemberPageName()} action={"create"}>
          <SceneCreate />
        </AuthProvider>
      ),
    },
    {
      path: "/members/redeemed-codes/create/:id",
      element: (
        <AuthProvider page={getMemberPageName()} action={"create"}>
          <RedeemedCreate />
        </AuthProvider>
      ),
    },
    {
      path: "/members/redeemed-codes/:id",
      element: (
        <AuthProvider page={getMemberPageName()} action={"update"}>
          <RedeemedEdit />
        </AuthProvider>
      ),
    },
  ],
};

export default MemberRoute;
