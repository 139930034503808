import MuiAppBar from "./MuiAppBar.js";
import MuiButton from "./MuiButton.js";
import MuiDateCalendar from "./MuiDateCalendar.js";

const components = {
  MuiAppBar: MuiAppBar,
  MuiButton: MuiButton,
  MuiDateCalendar: MuiDateCalendar,
};

export default components;
