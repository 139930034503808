import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Autocomplete,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import ReactSwal from "../../helper/AlertHelper";
import { statusList } from "../../helper/GlobalValueHelper";
import DebugLogger from "../DebugLogger";
import UpdaterInfo from "../UpdaterInfo";
import MemberScenesTransform from "../member/MemberScenesTransform";

export default function MemberScenesInfo({
  memberScene,
  setMemberScene,
  deleteMemberScenes,
  haveDeletePermission,
  collections,
  collectionPage,
  collectionPageSize,
  collectionTotal,
  setCollectionPage,
  backdrops,
  backdropPage,
  backdropPageSize,
  backdropTotal,
  setBackdropPage,
  effects,
  effectPage,
  effectPageSize,
  effectTotal,
  setEffectPage,
  stages,
  stagePage,
  stagePageSize,
  stageTotal,
  setStagePage,
  setNull,
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showDeleteConfirm = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        deleteMemberScenes();
      }
    });
  };

  const onChangeCollectionPage = value => {
    setCollectionPage(value);
  };

  const onChangeBackdropPage = value => {
    setBackdropPage(value);
  };

  const onChangeEffectPage = value => {
    setEffectPage(value);
  };

  const onChangeStagePage = value => {
    setStagePage(value);
  };

  const handleCollectionCheckboxChange = image => {
    setMemberScene({
      ...memberScene,
      collection: {
        id: image.id,
        transform: {
          x: 0,
          y: 0,
          z: 0,
          x_rotate: 0,
          y_rotate: 0,
          z_rotate: 0,
          scale: 0,
        },
      },
    });
  };

  const handleBackdropCheckboxChange = image => {
    setMemberScene({
      ...memberScene,
      backdrop: {
        id: image.id,
        transform: {
          x: 0,
          y: 0,
          z: 0,
          x_rotate: 0,
          y_rotate: 0,
          z_rotate: 0,
          scale: 0,
        },
      },
    });
  };

  const handleEffectCheckboxChange = image => {
    setMemberScene({
      ...memberScene,
      effect: {
        id: image.id,
        transform: {
          x: 0,
          y: 0,
          z: 0,
          x_rotate: 0,
          y_rotate: 0,
          z_rotate: 0,
          scale: 0,
        },
      },
    });
  };

  const handleStageCheckboxChange = image => {
    setMemberScene({
      ...memberScene,
      stage: {
        id: image.id,
        transform: {
          x: 0,
          y: 0,
          z: 0,
          x_rotate: 0,
          y_rotate: 0,
          z_rotate: 0,
          scale: 0,
        },
      },
    });
  };

  const setCollectionValue = (key, value) => {
    setMemberScene(prevState => ({
      ...prevState,
      collection: {
        ...prevState.collection,
        transform: {
          ...prevState.collection.transform,
          [key]: value,
        },
      },
    }));
  };

  const setBackdropValue = (key, value) => {
    setMemberScene(prevState => ({
      ...prevState,
      backdrop: {
        ...prevState.backdrop,
        transform: {
          ...prevState.backdrop.transform,
          [key]: value,
        },
      },
    }));
  };

  const setEffectValue = (key, value) => {
    setMemberScene(prevState => ({
      ...prevState,
      effect: {
        ...prevState.effect,
        transform: {
          ...prevState.effect.transform,
          [key]: value,
        },
      },
    }));
  };

  const setStageValue = (key, value) => {
    setMemberScene(prevState => ({
      ...prevState,
      stage: {
        ...prevState.stage,
        transform: {
          ...prevState.stage.transform,
          [key]: value,
        },
      },
    }));
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={memberScene.id ? t("member.edit_member_scenes") : t("member.new_member_scenes")}
        action={
          deleteMemberScenes && haveDeletePermission ? (
            <Box>
              <IconButton onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={showDeleteConfirm}>
                  <Translation>
                    {t => <Typography color={"error"}>{t("layout.delete")}</Typography>}
                  </Translation>
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <Box />
          )
        }
      />

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DebugLogger title={"Member Scenes"} data={memberScene} hidden={true} />
            <DebugLogger title={"Collections"} data={collections} hidden={true} />
            <DebugLogger title={"Backdrops"} data={backdrops} hidden={true} />
            <DebugLogger title={"Effects"} data={effects} hidden={true} />
            <DebugLogger title={"Stages"} data={stages} hidden={true} />
          </Grid>

          <Grid item xs={12}>
            <MemberScenesTransform
              header={t("member.collection")}
              type={"collection"}
              list={collections}
              data={memberScene.collection}
              handleCheckboxChange={handleCollectionCheckboxChange}
              total={collectionTotal}
              page={collectionPage}
              pageSize={collectionPageSize}
              onChangePage={onChangeCollectionPage}
              setData={setCollectionValue}
              setNull={setNull}
            />
          </Grid>

          <Grid item xs={12}>
            <MemberScenesTransform
              header={t("member.backdrop")}
              type={"backdrop"}
              list={backdrops}
              data={memberScene.backdrop}
              handleCheckboxChange={handleBackdropCheckboxChange}
              total={backdropTotal}
              page={backdropPage}
              pageSize={backdropPageSize}
              onChangePage={onChangeBackdropPage}
              setData={setBackdropValue}
              setNull={setNull}
            />
          </Grid>

          <Grid item xs={12}>
            <MemberScenesTransform
              header={t("member.effect")}
              type={"effect"}
              list={effects}
              data={memberScene.effect}
              handleCheckboxChange={handleEffectCheckboxChange}
              total={effectTotal}
              page={effectPage}
              pageSize={effectPageSize}
              onChangePage={onChangeEffectPage}
              setData={setEffectValue}
              setNull={setNull}
            />
          </Grid>

          <Grid item xs={12}>
            <MemberScenesTransform
              header={t("member.stage")}
              type={"stage"}
              list={stages}
              data={memberScene.stage}
              handleCheckboxChange={handleStageCheckboxChange}
              total={stageTotal}
              page={stagePage}
              pageSize={stagePageSize}
              onChangePage={onChangeStagePage}
              setData={setStageValue}
              setNull={setNull}
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              options={statusList}
              getOptionLabel={option => {
                return t(`member.scenes_${option.name}`);
              }}
              value={memberScene.state}
              filterSelectedOptions
              isOptionEqualToValue={(opt, value) => {
                return opt.key === value.key;
              }}
              onChange={(event, newValue) => {
                setMemberScene({ ...memberScene, state: newValue });
              }}
              renderInput={params => <TextField {...params} label={t("layout.state")} />}
            />
          </Grid>

          <UpdaterInfo data={memberScene} />
        </Grid>
      </CardContent>

      <CardActions sx={{ justifyContent: "space-between", px: 2 }}></CardActions>
    </Card>
  );
}
