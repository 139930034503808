import AuthProvider from "../components/AuthProvider";
import DashboardLayout from "../components/DashboardLayout";
import { getPermissionModulePageName } from "../helper/GlobalValueHelper";
import Create from "../views/permission/module/Create";
import Edit from "../views/permission/module/Edit";
import List from "../views/permission/module/List";

const PermissionModuleRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/permission-module",
      element: (
        <AuthProvider page={getPermissionModulePageName()} action={"view"}>
          <List />
        </AuthProvider>
      ),
    },
    {
      path: "permission-module/create",
      element: (
        <AuthProvider page={getPermissionModulePageName()} action={"create"}>
          <Create />
        </AuthProvider>
      ),
    },
    {
      path: "permission-module/:id",
      element: (
        <AuthProvider page={getPermissionModulePageName()} action={"update"}>
          <Edit />
        </AuthProvider>
      ),
    },
  ],
};

export default PermissionModuleRoute;
