import { Breadcrumbs, Container, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

// components
import ActionFooter from "../../components/ActionFooter";
import FullScreenLoading from "../../components/FullScreenLoading";
import MemberBaseInfo from "../../components/member/MemberBaseInfo";

// api
import { MemberApi } from "../../api/MemberApi";

// validation schema
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required(<Translation>{t => t("error.email_is_required")}</Translation>)
    .email(<Translation>{t => t("error.email_invalid_format")}</Translation>),
  points: Yup.number().test(
    "isPostive?",
    <Translation>{t => t("error.invalid_format")}</Translation>,
    val => val >= 0,
  ),
  password: Yup.string()
    .required(<Translation>{t => t("error.password_is_required")}</Translation>)
    .min(8, <Translation>{t => t("error.passowrd_length_min_8_characters")}</Translation>)
    .matches(/((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/, {
      excludeEmptyString: false,
      message: <Translation>{t => t("error.password_letter")}</Translation>,
    }),
});

export default function MemberCreate({ onClose, onCreated }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      lang: {
        key: 1,
        name: "en",
        value: "en",
      },
      role: {
        key: 2,
        name: "user",
        value: "user",
      },
      points: 0,
      state: {
        key: 1,
        name: "active",
        value: "active",
      },
    },
    validateOnMount: true,
    validationSchema: validationSchema,
  });
  const [fullScreenLoading, setFullScreenLoading] = useState(false);

  const onSubmit = async () => {
    try {
      formik.setTouched({
        email: true,
        password: true,
        points: true,
      });

      formik.validateForm();

      if (!formik.isValid) {
        return;
      }

      const member = {
        ...formik.values,
      };

      setFullScreenLoading(true);

      const newMember = await MemberApi.create(member);
      setFullScreenLoading(false);

      if (newMember) {
        toast.success(t("layout.created_successfully"));
        navigate("/members");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/members"}>{t("menu.account")}</Link>
        <Typography color="text.primary">{t("layout.create")}</Typography>
      </Breadcrumbs>
      <MemberBaseInfo formik={formik} isCreate={true} />
      <ActionFooter onSubmit={onSubmit} onCancel={onCancel} />
    </Container>
  );
}
