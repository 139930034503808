import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Autocomplete,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactSwal from "../../helper/AlertHelper";
import { reuseList, statusList } from "../../helper/GlobalValueHelper";
import DebugLogger from "../DebugLogger";
import UpdaterInfo from "../UpdaterInfo";
import RedeemCodeGiftsList from "../redeemCode/RedeemCodeGiftsList";

export default function RedeemCodeInfo({
  data,
  setData,
  deleteData,
  haveDeletePermission,
  backdrops,
  backdropPage,
  backdropPageSize,
  backdropTotal,
  setBackdropPage,
  effects,
  effectPage,
  effectPageSize,
  effectTotal,
  setEffectPage,
  stages,
  stagePage,
  stagePageSize,
  stageTotal,
  setStagePage,
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showDeleteConfirm = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        deleteData();
      }
    });
  };

  const onChangeBackdropPage = value => {
    setBackdropPage(value);
  };

  const onChangeEffectPage = value => {
    setEffectPage(value);
  };

  const onChangeStagePage = value => {
    setStagePage(value);
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={data.id ? t("redeemCode.edit_redeem_code") : t("redeemCode.new_redeem_code")}
        action={
          deleteData && haveDeletePermission ? (
            <Box>
              <IconButton onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={showDeleteConfirm}>
                  <Typography color={"error"}>{t("layout.delete")}</Typography>
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <Box />
          )
        }
      />

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DebugLogger title={"Redeem Code"} data={data} hidden={true} />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              value={data.code}
              onChange={event => setData({ ...data, code: event.target.value })}
              required
              fullWidth
              autoFocus
              label={t("redeemCode.code")}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                inputFormat="YYYY-MM-DD"
                slotProps={{ textField: { fullWidth: true, required: true } }}
                label={t("redeemCode.expire_at")}
                value={moment(data.expireAt || "")}
                onChange={newValue => {
                  setData({ ...data, expireAt: newValue.format("YYYY-MM-DD 23:59:59") });
                }}
                minDate={moment()}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              type="number"
              value={data.limit || 0}
              onChange={event => setData({ ...data, limit: event.target.value })}
              fullWidth
              autoFocus
              label={t("redeemCode.limit")}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Autocomplete
              options={reuseList}
              getOptionLabel={option => {
                return t(`redeemCode.${option.name}`);
              }}
              value={data.reuse}
              filterSelectedOptions
              isOptionEqualToValue={(opt, value) => {
                return opt.key === value.key;
              }}
              onChange={(event, newValue) => {
                setData({ ...data, reuse: newValue });
              }}
              renderInput={params => <TextField {...params} label={t("redeemCode.reuse")} />}
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              options={statusList}
              getOptionLabel={option => {
                return t(`redeemCode.${option.name}`);
              }}
              value={data.state}
              filterSelectedOptions
              isOptionEqualToValue={(opt, value) => {
                return opt.key === value.key;
              }}
              onChange={(event, newValue) => {
                setData({ ...data, state: newValue });
              }}
              renderInput={params => <TextField {...params} label={t("layout.state")} />}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t("redeemCode.remark")}
              multiline
              value={data.remark}
              onChange={event => {
                setData({ ...data, remark: event.target.value });
              }}
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">{t("redeemCode.gifts")}</Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              type="number"
              value={data.gifts.points || 0}
              onChange={event =>
                setData({
                  ...data,
                  gifts: { ...data.gifts, points: event.target.value < 0 ? 0 : event.target.value },
                })
              }
              fullWidth
              autoFocus
              label={t("redeemCode.points")}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              type="number"
              value={data.gifts.collectionSlot || 0}
              onChange={event =>
                setData({
                  ...data,
                  gifts: { ...data.gifts, collectionSlot: event.target.value < 0 ? 0 : event.target.value },
                })
              }
              fullWidth
              autoFocus
              label={t("redeemCode.collection_slot")}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              type="number"
              value={data.gifts.scenesSlot || 0}
              onChange={event =>
                setData({
                  ...data,
                  gifts: { ...data.gifts, scenesSlot: event.target.value < 0 ? 0 : event.target.value },
                })
              }
              fullWidth
              autoFocus
              label={t("redeemCode.scenes_slot")}
            />
          </Grid>

          <Grid item xs={12}>
            <RedeemCodeGiftsList
              header={t("redeemCode.effects")}
              data={data?.gifts?.effects}
              setData={(e, item) =>
                setData({
                  ...data,
                  gifts: {
                    ...data.gifts,
                    effects: e.target.checked
                      ? [...data.gifts.effects, item.id]
                      : data.gifts.effects.filter(x => x !== item.id),
                  },
                })
              }
              page={effectPage}
              pageSize={effectPageSize}
              total={effectTotal}
              onChangePage={onChangeEffectPage}
              list={effects}
              setNull={() =>
                setData({
                  ...data,
                  gifts: {
                    ...data.gifts,
                    effects: [],
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12}>
            <RedeemCodeGiftsList
              header={t("redeemCode.stages")}
              data={data?.gifts?.stages}
              setData={(e, item) =>
                setData({
                  ...data,
                  gifts: {
                    ...data.gifts,
                    stages: e.target.checked
                      ? [...data.gifts.stages, item.id]
                      : data.gifts.stages.filter(x => x !== item.id),
                  },
                })
              }
              page={stagePage}
              pageSize={stagePageSize}
              total={stageTotal}
              onChangePage={onChangeStagePage}
              list={stages}
              setNull={() =>
                setData({
                  ...data,
                  gifts: {
                    ...data.gifts,
                    stages: [],
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12}>
            <RedeemCodeGiftsList
              header={t("redeemCode.backdrops")}
              data={data?.gifts?.backdrops}
              setData={(e, item) =>
                setData({
                  ...data,
                  gifts: {
                    ...data.gifts,
                    backdrops: e.target.checked
                      ? [...data.gifts.backdrops, item.id]
                      : data.gifts.backdrops.filter(x => x !== item.id),
                  },
                })
              }
              page={backdropPage}
              pageSize={backdropPageSize}
              total={backdropTotal}
              onChangePage={onChangeBackdropPage}
              list={backdrops}
              setNull={() =>
                setData({
                  ...data,
                  gifts: {
                    ...data.gifts,
                    backdrops: [],
                  },
                })
              }
            />
          </Grid>

          <UpdaterInfo data={data} />
        </Grid>
      </CardContent>

      <CardActions sx={{ justifyContent: "space-between", px: 2 }}></CardActions>
    </Card>
  );
}
