import { Box, Breadcrumbs, Container, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// components
import ActionFooter from "../../components/ActionFooter";
import FullScreenLoading from "../../components/FullScreenLoading";
import FilterInfo from "../../components/filter/FilterInfo";

// api
import { FilterApi } from "../../api/FilterApi";

export default function FilterCreate() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState({
    type: {
      key: 1,
      name: "collection",
      value: "collection",
    },
    subType: "",
    tag: "",
    state: {
      key: 1,
      name: "active",
      value: "active",
    },
  });
  const [fullScreenLoading, setFullScreenLoading] = useState(false);

  const validateForm = () => {
    let error = false;

    if (data.type.length == 0) {
      toast.error(t("error.type_is_required"));
      error = true;
    }

    if (data.subType.length == 0) {
      toast.error(t("error.sub_type_is_required"));
      error = true;
    }

    if (data.tag.length == 0) {
      toast.error(t("error.tag_is_required"));
      error = true;
    }

    return error;
  };

  const onSubmit = async () => {
    try {
      let error = validateForm();

      if (error) {
        return;
      }

      setFullScreenLoading(true);
      const id = await FilterApi.create(data);
      if (id) {
        toast.success(t("layout.created_successfully"));
        navigate(`/filter`);
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/filter"}>{t("menu.filter")}</Link>
        <Typography color="text.primary">{t("layout.create")}</Typography>
      </Breadcrumbs>
      <Box component="form">
        <FilterInfo data={data} setData={setData} />
        <Box sx={{ my: 8 }} />
        <ActionFooter onSubmit={onSubmit} onCancel={onCancel} />
      </Box>
    </Container>
  );
}
