import { Box, Button, Container, Link, Typography } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";

export default function PageNotFound() {
  const { t } = useTranslation();

  return (
    <Container maxWidth={false}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={4}
        sx={{
          height: "calc(100vh - 128px)",
        }}
      >
        <Typography variant="h5">{t("menu.dashboard")}</Typography>
        <Button component={Link} href="/">
          {t("page_not_found.back_to_home")}
        </Button>
      </Box>
    </Container>
  );
}
