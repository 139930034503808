import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReactSwal from "../../helper/AlertHelper";
import { maxUploadFileMb } from "../../helper/GlobalValueHelper";
import { checkPermission } from "../../helper/PermissionHelper";
import { pageName } from "../../reducers/commonSlice";
import { user } from "../../reducers/userSlice";
import DebugLogger from "../DebugLogger";
import ImageUploader from "../ImageUploader";
import UpdaterInfo from "../UpdaterInfo";

export default function PaymentMethodInfo({
  paymentMethod,
  setPaymentMethod,
  deletePaymentMethod,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [haveDeletePermission, setHaveDeletePermission] = useState(false);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);
  const [images, setImages] = useState([]);
  const [showImageUploader, setShowImageUploader] = useState(false);

  useEffect(() => {
    if (!paymentMethod) return;

    let imageLength = paymentMethod.icon?.length;
    imageLength += paymentMethod.uploadFiles?.length || 0;

    let variantLength = 1;

    if (imageLength >= variantLength) {
      setShowImageUploader(false);
    } else {
      setShowImageUploader(true);
    }
  }, [paymentMethod]);

  useEffect(() => {
    const deletePermission = checkPermission(currentUser, currentPage, "delete");

    setHaveDeletePermission(deletePermission);
  }, [currentUser, currentPage]);

  const onImageChange = images => {
    let uploadFiles = paymentMethod.uploadFiles || [];

    // check if file exists
    let fileExists = false;
    uploadFiles.forEach(file => {
      images.forEach(image => {
        if (file.name === image.file.name) {
          fileExists = true;
          return;
        }
      });
    });

    if (fileExists) {
      return;
    }

    if (images && images.length > 0) {
      images.forEach((image, index) => {
        if (image.file.size > 1024 * 1024 * maxUploadFileMb) {
          toast.error(`Image ${index + 1} file size must be less than ${maxUploadFileMb}MB`);
          return;
        }
        uploadFiles.push(image.file);
      });
    }
    setPaymentMethod({ ...paymentMethod, uploadFiles: uploadFiles });
    images = null;
  };

  const onRemoveImage = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        setPaymentMethod({
          ...paymentMethod,
          icon: '',
        });
      }
    });
  };

  const removeUploadFile = index => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        let newUploadFiles = paymentMethod.uploadFiles;
        newUploadFiles.splice(index, 1);
        setPaymentMethod({ ...paymentMethod, uploadFiles: newUploadFiles });
      }
    });
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showDeleteConfirm = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        deletePaymentMethod();
      }
    });
  };

  return (
    <Box>
      <Card variant="outlined">
        <CardHeader
          title={t("layout.payment_method")}
          action={
            deletePaymentMethod && haveDeletePermission ? (
              <Box>
                <IconButton onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={showDeleteConfirm}>
                    <Typography color={"error"}>{t("layout.delete")}</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <Box />
            )
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DebugLogger title={"Payment Method"} data={paymentMethod} hidden={true} />
            </Grid>

            <Grid item xs={12}>
              <Typography
                sx={{
                  pb: 2,
                }}
              >
                {t("layout.cover_image")}
              </Typography>

              <Box
                sx={{
                  mb: 2,
                  fontWight: "bold",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography variant="caption">
                  {t("page.maximum_upload_size")}: {maxUploadFileMb} MB
                </Typography>
                <Typography variant="caption">
                  {t("page.accept_file_type")}: JPEG / JPG / PNG / WEBP / SVG
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                {paymentMethod.icon && (
                  <Grid item xs={12}>
                    <Stack>
                      <Box
                        component={"img"}
                        src={paymentMethod.icon}
                        sx={{
                          objectFit: "contain",
                          maxWidth: "100%",
                          maxHeight: "200px",
                        }}
                      />
                      <Button
                        sx={{
                          p: 2,
                        }}
                        onClick={() => {
                          onRemoveImage();
                        }}
                      >
                        {t("layout.remove")}
                      </Button>
                    </Stack>
                  </Grid>
                )}

                {paymentMethod.uploadFiles &&
                  paymentMethod.uploadFiles.length > 0 &&
                  paymentMethod.uploadFiles.map((file, index) => (
                    <Grid item xs={12}>
                      <Stack>
                        <Box
                          component={"img"}
                          src={URL.createObjectURL(file)}
                          sx={{
                            objectFit: "contain",
                            maxWidth: "100%",
                            maxHeight: "200px",
                          }}
                        />
                        <Button
                          sx={{
                            p: 2,
                          }}
                          onClick={() => {
                            removeUploadFile(index);
                          }}
                        >
                          {t("layout.remove")}
                        </Button>
                      </Stack>
                    </Grid>
                  ))}
              </Grid>
              {showImageUploader && (
                <ImageUploader
                  maxNumber={1}
                  images={images}
                  maxFileSize={1024 * 1024 * maxUploadFileMb}
                  onImageChange={onImageChange}
                  onRemoveImage={onRemoveImage}
                  removeAllButton={false}
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t("layout.name")}
                value={paymentMethod.name}
                onChange={event => {
                  setPaymentMethod({
                    ...paymentMethod,
                    name: event.target.value,
                  });
                }}
                sx={{ mb: 2 }}
              />
            </Grid>
            <UpdaterInfo data={paymentMethod} />
          </Grid>
          {/* <Box
            display={"flex"}
            flexDirection={"column"}
            gap={2}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {paymentMethod.icon ? (
              <Box display={"flex"} flexDirection={"column"}>
                <Box sx={{ height: 100, p: 1 }} component={"img"} src={paymentMethod.icon} />
                <Button
                  onClick={() => {
                    setPaymentMethod({
                      ...paymentMethod,
                      icon: null,
                    });
                  }}
                >
                  {t("layout.delete")}
                </Button>
              </Box>
            ) : (
              <ImageUploader
                maxNumber={1}
                exitingImages={images}
                maxFileSize={1024 * 1024 * maxUploadFileMb}
                onImageChange={onImageChange}
                onRemoveImage={onRemoveImage}
                removeAllButton={false}
              />
            )}
          </Box> */}
        </CardContent>
      </Card>
    </Box>
  );
}
