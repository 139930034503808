import AuthProvider from "../components/AuthProvider";
import DashboardLayout from "../components/DashboardLayout";
import { getPaymentMethodPageName } from "../helper/GlobalValueHelper";
import Create from "../views/payment/Create";
import Edit from "../views/payment/Edit";
import List from "../views/payment/List";

const PaymentRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/payment-method",
      element: (
        <AuthProvider page={getPaymentMethodPageName()} action={"view"}>
          <List />
        </AuthProvider>
      ),
    },
    {
      path: "/payment-method/create",
      element: (
        <AuthProvider page={getPaymentMethodPageName()} action={"create"}>
          <Create />
        </AuthProvider>
      ),
    },
    {
      path: "/payment-method/:id",
      element: (
        <AuthProvider page={getPaymentMethodPageName()} action={"update"}>
          <Edit />
        </AuthProvider>
      ),
    },
  ],
};

export default PaymentRoute;
