import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Autocomplete,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import ReactSwal from "../../helper/AlertHelper";
import DebugLogger from "../DebugLogger";
import UpdaterInfo from "../UpdaterInfo";
import ValidationTextField from "../ValidationTextField";

export default function PermissionModuleBaseInfo({
  formik,
  haveDeletePermission,
  deletePermissionModule,
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showDeleteConfirm = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        deletePermissionModule();
      }
    });
  };

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 6,
      }}
    >
      <CardHeader
        title={t("permission.permission_module_management")}
        subheader={
          <Typography variant="caption">{t("permission.manage_permission_module")}</Typography>
        }
        action={
          deletePermissionModule && haveDeletePermission ? (
            <Box>
              <IconButton onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={showDeleteConfirm}>
                  <Translation>
                    {t => <Typography color={"error"}>{t("layout.delete")}</Typography>}
                  </Translation>
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <Box />
          )
        }
      />

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DebugLogger title="Permission Module" data={formik.values} hidden={true}></DebugLogger>
          </Grid>

          <Grid item xs={12}>
            <ValidationTextField
              name={"module"}
              type={"text"}
              required={true}
              value={formik.values.module}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title={t("permission.module")}
              errorText={formik.errors.module}
              touched={formik.touched.module}
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              multiple
              options={formik.values.actions.map(option => option)}
              freeSolo
              value={formik.values.actions || []}
              onChange={(event, newValue) => {
                formik.setFieldValue("actions", newValue);
              }}
              renderTags={(value, getTagProps) =>
                value &&
                value.map((option, index) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} key={option} />
                ))
              }
              filterSelectedOptions
              renderInput={params => (
                <TextField
                  required
                  {...params}
                  label={t("permission.action")}
                  placeholder={t("permission.please_enter_to_confirm_add_action")}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: 2,
          }}
        >
          <UpdaterInfo data={formik.values} />
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: "space-between", px: 2 }}></CardActions>
    </Card>
  );
}
