const typography = {
  fontFamily: ["Noto Sans HK", "sans - serif"].join(","),
  // htmlFontSize: 10,
  fontWeightBold: 900,

  h1: {
    fontSize: "5rem",
    fontWeight: 900,
    lineHeight: 1.25,
    letterSpacing: "-0.01em",
  },
  h2: {
    fontSize: "5rem",
    fontWeight: 900,
    lineHeight: 1.35,
    letterSpacing: "-0.01em",
  },
  h3: {
    fontSize: "4rem",
    fontWeight: 700,
    lineHeight: 1.35,
    letterSpacing: "0.01em",
  },
  h4: {
    fontSize: "3rem",
    fontWeight: 700,
    lineHeight: 1.35,
    letterSpacing: "0em",
  },
  h5: {
    fontSize: "2rem",
    fontWeight: 700,
    lineHeight: 1.35,
  },
  h6: {
    fontSize: "1.1rem",
    fontWeight: 700,
    lineHeight: 1.35,
  },
  subTitle: {
    fontSize: "1.2rem",
    fontWeight: 700,
    lineHeight: 1.35,
  },
  subTitle_2: {
    fontSize: "1.5rem",
    fontWeight: 700,
    lineHeight: 1.35,
  },
  body1: {
    fontSize: "1rem",
    lineHeight: 1.55,
    letterSpacing: "0em",
  },
  body2: {
    fontSize: "0.8rem",
    lineHeight: 1.55,
    letterSpacing: "0em",
  },
  caption: {
    fontSize: "0.724rem",
    lineHeight: 1.65,
    letterSpacing: "0em",
  },
  button: {
    fontSize: "1rem",
    fontWeight: 700,
    lineHeight: 1,
    letterSpacing: "0.04em",
    fontFamily: "Noto Sans HK",
  },
};

export default typography;
