import axios from "../helper/AxiosHelper";
import { langList, roleList, stateList } from "../helper/GlobalValueHelper";

const list = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "id.DESC",
  };
  const response = await axios.get(`/member/query?query=${JSON.stringify(query)}&fields=all`);
  const result = response.data.result;
  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        email: data.email,
        lang: data.lang ? langList.find(lang => lang.value === data.lang) : null,
        role: data.role ? roleList.find(role => role.value === data.role) : null,
        points: data.points,
        state: data.state ? stateList.find(state => state.value === data.state) : null,
        updatedAt: data.updated_at,
        createdAt: data.created_at,
        updatedBy: data.updated_by_info ? data.updated_by_info.username : "",
        createdBy: data.created_by_info ? data.created_by_info.username : "",
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const details = async id => {
  const response = await axios.get(`member/show/${id}?fields=all`);
  const result = response.data.result;
  return {
    id: result.id,
    email: result.email,
    lang: result.lang ? langList.find(lang => lang.value === result.lang) : null,
    role: result.role ? roleList.find(role => role.value === result.role) : null,
    points: result.points,
    state: result.state ? stateList.find(state => state.value === result.state) : null,
    updatedAt: result.updated_at,
    createdAt: result.created_at,
    updatedBy: result.updated_by_info ? result.updated_by_info.username : "",
    createdBy: result.created_by_info ? result.created_by_info.username : "",
  };
};

const create = async member => {
  const formData = new FormData();
  const data = {
    email: member.email,
    password: member.password,
    lang: member.lang ? member.lang.value : undefined,
    role: member.role ? member.role.value : undefined,
    points: member.points,
    state: member.state ? member.state.value : undefined,
    upload_files: member.uploadFiles?.length || undefined,
  };

  formData.append("data", JSON.stringify(data));

  if (member.uploadFiles) {
    member.uploadFiles.forEach((file, index) => {
      formData.append(`file_${index + 1}`, file);
    });
  }

  const response = await axios.post("/member/create", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data.result.insert_id;
};

const update = async (id, member) => {
  const formData = new FormData();
  const data = {
    email: member.email,
    password: member.password != "" ? member.password : undefined,
    lang: member.lang ? member.lang.value : undefined,
    role: member.role ? member.role.value : undefined,
    points: member.points,
    state: member.state ? member.state.value : undefined,
    upload_files: member.uploadFiles || undefined,
  };

  formData.append("data", JSON.stringify(data));

  if (member.uploadFiles) {
    member.uploadFiles.forEach((file, index) => {
      formData.append(`file_${index + 1}`, file);
    });
  }

  const response = await axios.post(`member/update/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data.success;
};

const deleteMember = async id => {
  const response = await axios.post(`member/delete/${id}`);
  return response.data.success;
};

const memberPointsHistorylist = async (memberID, page) => {
  const query = {
    filters: [{ field: "member_id", value: memberID, op: "eq"}],
    page: page || 1,
    page_size: 10,
    sort: "id.DESC",
  };
  const response = await axios.get(
    `/memberPointsHistory/query?query=${JSON.stringify(query)}&fields=all`,
  );
  const result = response.data.result;

  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        event: data.event,
        qtyChange: data.qty_change,
        remark: data.remark,
        balance: data.balance,
        createdAt: data.created_at,
        createdBy: data.created_by_info ? data.created_by_info.username : "",
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const changeMemberPoints = async changes => {
  const data = {
    member_id: changes.memberID,
    event: changes.event,
    qty_change: changes.qtyChange,
    remark: changes.remark,
  };

  const response = await axios.post("/member/changeMemberPoints", data);

  return response.data.success;
};

const memberEffectsList = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "id.DESC",
  };
  const response = await axios.get(`/memberEffect/query?query=${JSON.stringify(query)}&fields=all`);
  const result = response.data.result;
  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        member: data.member_id_info ? data.member_id_info : null,
        refItem: data.ref_item_info ? data.ref_item_info : null,
        state: data.state ? stateList.find(state => state.value === data.state) : null,
        updatedAt: data.updated_at,
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const memberEffectsDetails = async id => {
  const response = await axios.get(`memberEffect/show/${id}?fields=all`);
  const result = response.data.result;
  return {
    id: result.id,
    memberId: result.member_id_info ? result.member_id_info : null,
    refItem: result.ref_item ? result.ref_item : null,
    state: result.state ? stateList.find(state => state.value === result.state) : null,
    updatedAt: result.updated_at,
  };
};

const memberEffectsCreate = async input => {
  const data = {
    member_id: input.memberId.id,
    ref_item: input.refItem,
    state: input.state ? input.state.value : undefined,
  };

  const response = await axios.post(`memberEffect/create`, data);

  return response.data.result.insert_id;
};

const memberEffectsUpdate = async (id, input) => {
  const data = {
    member_id: input.memberId.id,
    ref_item: input.refItem,
    state: input.state ? input.state.value : undefined,
  };

  const response = await axios.post(`memberEffect/update/${id}`, data);
  return response.data.success;
};

const deleteMemberEffects = async id => {
  const response = await axios.post(`memberEffect/delete/${id}`);
  return response.data.success;
};

const memberStagesList = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "id.DESC",
  };
  const response = await axios.get(`/memberStage/query?query=${JSON.stringify(query)}&fields=all`);
  const result = response.data.result;
  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        member: data.member_id_info ? data.member_id_info : null,
        refItem: data.ref_item_info ? data.ref_item_info : null,
        state: data.state ? stateList.find(state => state.value === data.state) : null,
        updatedAt: data.updated_at,
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const memberStagesDetails = async id => {
  const response = await axios.get(`memberStage/show/${id}?fields=all`);
  const result = response.data.result;
  return {
    id: result.id,
    memberId: result.member_id_info ? result.member_id_info : null,
    refItem: result.ref_item ? result.ref_item : null,
    state: result.state ? stateList.find(state => state.value === result.state) : null,
    updatedAt: result.updated_at,
  };
};

const memberStagesCreate = async input => {
  const data = {
    member_id: input.memberId.id,
    ref_item: input.refItem,
    state: input.state ? input.state.value : undefined,
  };

  const response = await axios.post(`memberStage/create`, data);

  return response.data.result.insert_id;
};

const memberStagesUpdate = async (id, input) => {
  const data = {
    member_id: input.memberId.id,
    ref_item: input.refItem,
    state: input.state ? input.state.value : undefined,
  };

  const response = await axios.post(`memberStage/update/${id}`, data);
  return response.data.success;
};

const deleteMemberStages = async id => {
  const response = await axios.post(`memberStage/delete/${id}`);
  return response.data.success;
};

const memberCollectionsList = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "id.DESC",
  };
  const response = await axios.get(
    `/memberCollectionSlot/query?query=${JSON.stringify(query)}&fields=all`,
  );
  const result = response.data.result;
  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        customCoverImage: data.custom_cover_image || "",
        member: data.member_id_info ? data.member_id_info : null,
        customTitle: data.custom_title || "",
        state: data.state ? stateList.find(state => state.value === data.state) : null,
        updatedBy: data.updated_by_info ? data.updated_by_info.username : "",
        updatedAt: data.updated_at,
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const memberCollectionsDetails = async id => {
  const response = await axios.get(`memberCollectionSlot/show/${id}?fields=all`);
  const result = response.data.result;
  return {
    id: result.id,
    memberId: result.member_id_info ? result.member_id_info : null,
    customCoverImage: result.custom_cover_image || "",
    customTitle: result.custom_title || "",
    customDesc: result.custom_desc || "",
    customTitle: result.custom_title || "",
    mediaSrc: result.media_src || "",
    refItem: result.ref_item || null,
    state: result.state ? stateList.find(state => state.value === result.state) : null,
    updatedAt: result.updated_at,
    createdAt: result.created_at,
    updatedBy: result.updated_by_info ? result.updated_by_info.username : "",
    createdBy: result.created_by_info ? result.created_by_info.username : "",
  };
};

const memberCollectionsCreate = async input => {
  const formData = new FormData();
  const data = {
    member_id: input.memberId.id,
    custom_cover_image: input.customCoverImage,
    custom_title: input.customTitle,
    custom_desc: input.customDesc,
    media_src: input.mediaSrc,
    ref_item: input.refItem,
    state: input.state ? input.state.value : undefined,
    upload_files: input.uploadFiles?.length || undefined,
    upload_asset_files: input.uploadAssetFiles?.length || undefined,
  };

  formData.append("data", JSON.stringify(data));

  if (input.uploadFiles) {
    input.uploadFiles.forEach((file, index) => {
      formData.append(`file_${index + 1}`, file);
    });
  }

  if (input.uploadAssetFiles) {
    input.uploadAssetFiles.forEach((file, index) => {
      formData.append(`asset_${index + 1}`, file);
    });
  }

  const response = await axios.post(`memberCollectionSlot/create`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data.result.insert_id;
};

const memberCollectionsUpdate = async (id, input) => {
  const formData = new FormData();
  const data = {
    member_id: input.memberId.id,
    custom_cover_image: input.customCoverImage,
    custom_title: input.customTitle,
    custom_desc: input.customDesc,
    media_src: input.mediaSrc,
    ref_item: input.refItem,
    state: input.state ? input.state.value : undefined,
    upload_files: input.uploadFiles?.length || undefined,
    upload_asset_files: input.uploadAssetFiles?.length || undefined,
  };

  formData.append("data", JSON.stringify(data));

  if (input.uploadFiles) {
    input.uploadFiles.forEach((file, index) => {
      formData.append(`file_${index + 1}`, file);
    });
  }

  if (input.uploadAssetFiles) {
    input.uploadAssetFiles.forEach((file, index) => {
      formData.append(`asset_${index + 1}`, file);
    });
  }

  const response = await axios.post(`memberCollectionSlot/update/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data.success;
};

const deleteMemberCollections = async id => {
  const response = await axios.post(`memberCollectionSlot/delete/${id}`);
  return response.data.success;
};

const memberBackdropsList = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "id.DESC",
  };
  const response = await axios.get(
    `/memberBackdrop/query?query=${JSON.stringify(query)}&fields=all`,
  );
  const result = response.data.result;
  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        member: data.member_id_info ? data.member_id_info : null,
        refItem: data.ref_item_info ? data.ref_item_info : null,
        state: data.state ? stateList.find(state => state.value === data.state) : null,
        updatedAt: data.updated_at,
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const memberBackdropsDetails = async id => {
  const response = await axios.get(`memberBackdrop/show/${id}?fields=all`);
  const result = response.data.result;
  return {
    id: result.id,
    memberId: result.member_id_info ? result.member_id_info : null,
    refItem: result.ref_item ? result.ref_item : null,
    state: result.state ? stateList.find(state => state.value === result.state) : null,
    updatedAt: result.updated_at,
  };
};

const memberBackdropsCreate = async input => {
  const data = {
    member_id: input.memberId.id,
    ref_item: input.refItem,
    state: input.state ? input.state.value : undefined,
  };

  const response = await axios.post(`memberBackdrop/create`, data);

  return response.data.result.insert_id;
};

const memberBackdropsUpdate = async (id, input) => {
  const data = {
    member_id: input.memberId.id,
    ref_item: input.refItem,
    state: input.state ? input.state.value : undefined,
  };

  const response = await axios.post(`memberBackdrop/update/${id}`, data);
  return response.data.success;
};

const deleteMemberBackdrops = async id => {
  const response = await axios.post(`memberBackdrop/delete/${id}`);
  return response.data.success;
};

const memberScenesList = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "id.DESC",
  };
  const response = await axios.get(
    `/memberSceneSlot/query?query=${JSON.stringify(query)}&fields=all`,
  );
  const result = response.data.result;
  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        member: data.member_id_info ? data.member_id_info : null,
        collection: data.s_collection_info ? data.s_collection_info : null,
        effect: data.s_effect_info ? data.s_effect_info : null,
        stage: data.s_stage_info ? data.s_stage_info : null,
        backdrop: data.s_backdrop_info ? data.s_backdrop_info : null,
        state: data.state ? stateList.find(state => state.value === data.state) : null,
        updatedBy: data.updated_by_info ? data.updated_by_info.username : "",
        updatedAt: data.updated_at,
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const memberScenesDetails = async id => {
  const response = await axios.get(`memberSceneSlot/show/${id}?fields=all`);
  const result = response.data.result;
  return {
    id: result.id,
    memberId: result.member_id_info ? result.member_id_info : null,
    collection: result.s_collection
      ? {
          id: result.s_collection_info.collection,
          transform: result.s_collection_info.transform || null,
        }
      : null,
    effect: result.s_effect
      ? {
          id: result.s_effect_info.effect,
          transform: result.s_effect_info.transform || null,
        }
      : null,
    stage: result.s_stage
      ? {
          id: result.s_stage_info.stage,
          transform: result.s_stage_info.transform || null,
        }
      : null,
    backdrop: result.s_backdrop
      ? {
          id: result.s_backdrop_info.backdrop,
          transform: result.s_backdrop_info.transform || null,
        }
      : null,
    state: result.state ? stateList.find(state => state.value === result.state) : null,
    updatedAt: result.updated_at,
    createdAt: result.created_at,
    updatedBy: result.updated_by_info ? result.updated_by_info.username : "",
    createdBy: result.created_by_info ? result.created_by_info.username : "",
  };
};

const memberScenesCreate = async input => {
  const data = {
    member_id: input.memberId.id,
    collection: input.collection,
    backdrop: input.backdrop,
    effect: input.effect,
    stage: input.stage,
    state: input.state ? input.state.value : undefined,
  };

  const response = await axios.post(`memberSceneSlot/create`, data);

  return response.data.result.insert_id;
};

const memberScenesUpdate = async (id, input) => {
  const data = {
    member_id: input.memberId.id,
    collection: input.collection,
    backdrop: input.backdrop,
    effect: input.effect,
    stage: input.stage,
    state: input.state ? input.state.value : undefined,
  };

  const response = await axios.post(`memberSceneSlot/update/${id}`, data);

  return response.data.success;
};

const deleteMemberScenes = async id => {
  const response = await axios.post(`memberSceneSlot/delete/${id}`);
  return response.data.success;
};

const memberRedeemedList = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "id.DESC",
  };
  const response = await axios.get(`/redeemedCode/query?query=${JSON.stringify(query)}&fields=all`);
  const result = response.data.result;
  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        memberId: data.member_id_info ? data.member_id_info : null,
        redeemCodeId: data.redeem_code_id_info ? data.redeem_code_id_info : null,
        state: data.state ? stateList.find(state => state.value === data.state) : null,
        updatedAt: data.updated_at,
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const memberRedeemedDetails = async id => {
  const response = await axios.get(`redeemedCode/show/${id}?fields=all`);
  const result = response.data.result;
  return {
    id: result.id,
    memberId: result.member_id_info ? result.member_id_info : null,
    redeemCodeId: result.redeem_code_id_info ? result.redeem_code_id_info : null,
    state: result.state ? stateList.find(state => state.value === result.state) : null,
    updatedAt: result.updated_at,
  };
};

const memberRedeemedCreate = async input => {
  const data = {
    member_id: input.memberId.id,
    code: input.redeemCodeId.code,
  };

  const response = await axios.post(`redeemCode/redeem`, data);

  return response.data.success;
};

const memberRedeemedUpdate = async (id, input) => {
  const data = {
    member_id: input.memberId.id,
    redeem_code_id: input.redeemCodeId.id,
    state: input.state ? input.state.value : undefined,
  };

  const response = await axios.post(`redeemedCode/update/${id}`, data);
  return response.data.success;
};

const deleteMemberRedeemedCode = async id => {
  const response = await axios.post(`redeemedCode/delete/${id}`);
  return response.data.success;
};

const resetMember = async id => {
  const response = await axios.post(`member/reset/${id}`);
  return response.data.success;
}

export const MemberApi = {
  list,
  details,
  create,
  update,
  deleteMember,
  memberPointsHistorylist,
  changeMemberPoints,
  memberEffectsList,
  memberEffectsDetails,
  memberEffectsCreate,
  memberEffectsUpdate,
  deleteMemberEffects,
  memberStagesList,
  memberStagesDetails,
  memberStagesCreate,
  memberStagesUpdate,
  deleteMemberStages,
  memberCollectionsList,
  memberCollectionsDetails,
  memberCollectionsCreate,
  memberCollectionsUpdate,
  memberBackdropsList,
  memberBackdropsDetails,
  memberBackdropsCreate,
  memberBackdropsUpdate,
  deleteMemberBackdrops,
  deleteMemberCollections,
  memberScenesList,
  memberScenesDetails,
  memberScenesCreate,
  memberScenesUpdate,
  deleteMemberScenes,
  memberRedeemedList,
  memberRedeemedDetails,
  memberRedeemedCreate,
  memberRedeemedUpdate,
  deleteMemberRedeemedCode,
  resetMember,
};
