import { Box, Breadcrumbs, Container, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// components
import ActionFooter from "../../components/ActionFooter";
import FullScreenLoading from "../../components/FullScreenLoading";
import BackdropInfo from "../../components/backdrop/BackdropInfo";

// api
import { BackdropApi } from "../../api/BackdropApi";

export default function BackdropCreate() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [backdrop, setBackdrop] = useState({
    coverImage: "",
    mediaSrc: "",
    assetsId: "",
    title: "",
    description: "",
    price: 0,
    tags: [],
    state: {
      key: 1,
      name: "active",
      value: "active",
    },
  });
  const [fullScreenLoading, setFullScreenLoading] = useState(false);

  const validateForm = () => {
    let error = false;

    if (backdrop.assetsId.length == 0) {
      toast.error(t("error.assets_id_is_required"));
      error = true;
    }

    if (backdrop.title.length == 0) {
      toast.error(t("error.name_is_required"));
      error = true;
    }

    if (backdrop.price.length == 0) {
      toast.error(t("error.price_is_required"));
      error = true;
    }

    return error;
  };

  const onSubmit = async () => {
    try {
      let error = validateForm();

      if (error) {
        return;
      }

      setFullScreenLoading(true);
      const id = await BackdropApi.create(backdrop);
      if (id) {
        toast.success(t("layout.created_successfully"));
        navigate(`/backdrop`);
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/backdrop"}>{t("menu.backdrop")}</Link>
        <Typography color="text.primary">{t("layout.create")}</Typography>
      </Breadcrumbs>
      <Box component="form">
        <BackdropInfo backdrop={backdrop} setBackdrop={setBackdrop} />
        <Box sx={{ my: 8 }} />
        <ActionFooter onSubmit={onSubmit} onCancel={onCancel} />
      </Box>
    </Container>
  );
}
