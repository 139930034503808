import { Box, Breadcrumbs, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

// components
import ActionFooter from "../../../components/ActionFooter";
import FullScreenLoading from "../../../components/FullScreenLoading";
import MemberScenesInfo from "../../../components/member/MemberScenesInfo";
import ReactSwal from "../../../helper/AlertHelper";

// api
import { MemberApi } from "../../../api/MemberApi";
import { OrderApi } from "../../../api/OrderApi";

export default function MemberSceneCreate() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [memberScene, setMemberScene] = useState({
    memberId: null,
    collection: null,
    backdrop: null,
    effect: null,
    stage: null,
    state: {
      key: 1,
      name: "active",
      value: "active",
    },
  });
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState();
  const [collections, setCollections] = useState([]);
  const [collectionPage, setCollectionPage] = useState(1);
  const [collectionPageSize, setCollectionPageSize] = useState(4);
  const [collectionFilter, setCollectionFilter] = useState([
    { field: "member_id", value: id, op: "eq" },
    { field: "custom_title", value: "", op: "gt" },
  ]);
  const [collectionTotal, setCollectionTotal] = useState(0);
  const [backdrops, setBackdrops] = useState([]);
  const [backdropPage, setBackdropPage] = useState(1);
  const [backdropPageSize, setBackdropPageSize] = useState(4);
  const [backdropFilter, setBackdropFilter] = useState([
    { field: "member_id", value: id, op: "eq" },
  ]);
  const [backdropTotal, setBackdropTotal] = useState(0);
  const [effects, setEffects] = useState([]);
  const [effectPage, setEffectPage] = useState(1);
  const [effectPageSize, setEffectPageSize] = useState(4);
  const [effectFilter, setEffectFilter] = useState([{ field: "member_id", value: id, op: "eq" }]);
  const [effectTotal, setEffectTotal] = useState(0);
  const [stages, setStages] = useState([]);
  const [stagePage, setStagePage] = useState(1);
  const [stagePageSize, setStagePageSize] = useState(4);
  const [stageFilter, setStageFilter] = useState([{ field: "member_id", value: id, op: "eq" }]);
  const [stageTotal, setStageTotal] = useState(0);

  useEffect(() => {
    if (page) {
      fetchMemberData();
    }

    if (collectionPage) {
      fetchCollectionsData();
    }

    if (backdropPage) {
      fetchBackdropsData();
    }

    if (effectPage) {
      fetchEffectsData();
    }

    if (stagePage) {
      fetchStagesData();
    }
  }, [page, collectionPage, backdropPage, effectPage, stagePage]);

  const fetchMemberData = async () => {
    try {
      setFullScreenLoading(true);
      const response = await MemberApi.details(id);
      setFullScreenLoading(false);
      setMemberScene({ ...memberScene, memberId: response });
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const fetchCollectionsData = async () => {
    try {
      setFullScreenLoading(true);
      const response = await MemberApi.memberCollectionsList(
        collectionPage,
        collectionPageSize,
        collectionFilter,
      );
      setFullScreenLoading(false);
      setCollections(response.data);
      setCollectionTotal(response.total);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const fetchBackdropsData = async () => {
    try {
      setFullScreenLoading(true);
      const response = await MemberApi.memberBackdropsList(
        backdropPage,
        backdropPageSize,
        backdropFilter,
      );
      setFullScreenLoading(false);
      setBackdrops(response.data);
      setBackdropTotal(response.total);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const fetchEffectsData = async () => {
    try {
      setFullScreenLoading(true);
      const response = await MemberApi.memberEffectsList(effectPage, effectPageSize, effectFilter);
      setFullScreenLoading(false);
      setEffects(response.data);
      setEffectTotal(response.total);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const fetchStagesData = async () => {
    try {
      setFullScreenLoading(true);
      const response = await MemberApi.memberStagesList(stagePage, stagePageSize, stageFilter);
      setFullScreenLoading(false);
      setStages(response.data);
      setStageTotal(response.total);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const validateForm = () => {
    let error = false;

    return error;
  };

  const onSubmit = async () => {
    try {
      let error = validateForm();

      if (error) {
        return;
      }

      setFullScreenLoading(true);
      const success = await MemberApi.memberScenesCreate(memberScene);
      if (success) {
        toast.success(t("layout.created_successfully"));
        navigate(`/members/${id}`);
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const update = async recordId => {
    try {
      let error = validateForm();

      if (error) {
        return;
      }

      setFullScreenLoading(true);
      const success = await MemberApi.memberScenesUpdate(recordId, memberScene);
      if (success) {
        toast.success(t("layout.created_successfully"));
        navigate(`/members/${id}`);
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCheckout = async () => {
    try {
      let error = validateForm();

      if (error) {
        return;
      }

      setFullScreenLoading(true);
      const response = await OrderApi.checkout(memberScene, "slot", "scene");
      if (response.success) {
        const recordId = response.result.ids[0];
        await update(recordId);
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const setNull = target => {
    setMemberScene({ ...memberScene, [target]: null });
  };

  const onCancel = () => {
    navigate(-1);
  };

  const showRedencePointConfirm = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.redence_point_confirm"),
      icon: "warning",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: t("layout.give_away"),
      denyButtonText: t("layout.not_give_away"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        onSubmit();
      } else if (result.isDenied) {
        onCheckout();
      }
    });
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/members"}>{t("menu.account")}</Link>
        <Link to={`/members/${id}`}>{t("menu.members")}</Link>
        <Typography color="text.primary">{t("layout.create")}</Typography>
      </Breadcrumbs>
      <Box component="form">
        <MemberScenesInfo
          memberScene={memberScene}
          setMemberScene={setMemberScene}
          collections={collections}
          collectionPage={collectionPage}
          collectionPageSize={collectionPageSize}
          collectionTotal={collectionTotal}
          setCollectionPage={setCollectionPage}
          backdrops={backdrops}
          backdropPage={backdropPage}
          backdropPageSize={backdropPageSize}
          backdropTotal={backdropTotal}
          setBackdropPage={setBackdropPage}
          effects={effects}
          effectPage={effectPage}
          effectPageSize={effectPageSize}
          effectTotal={effectTotal}
          setEffectPage={setEffectPage}
          stages={stages}
          stagePage={stagePage}
          stagePageSize={stagePageSize}
          stageTotal={stageTotal}
          setStagePage={setStagePage}
          setNull={setNull}
        />
        <Box sx={{ my: 8 }} />
        <ActionFooter onSubmit={showRedencePointConfirm} onCancel={onCancel} />
      </Box>
    </Container>
  );
}
