import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Autocomplete,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactSwal from "../../helper/AlertHelper";
import { statusList } from "../../helper/GlobalValueHelper";
import DebugLogger from "../DebugLogger";
import UpdaterInfo from "../UpdaterInfo";
// styles

export default function MemberRedeemedInfo({
  redeemCodes,
  data,
  setData,
  deleteData,
  haveDeletePermission,
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showDeleteConfirm = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        deleteData();
      }
    });
  };

  const handleCheckboxChange = item => {
    setData({
      ...data,
      redeemCodeId: item.id,
    });
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={
          data.id ? t("member.edit_member_redeemed_code") : t("member.new_member_redeemed_code")
        }
        action={
          deleteData && haveDeletePermission ? (
            <Box>
              <IconButton onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={showDeleteConfirm}>{t("layout.delete")}</MenuItem>
              </Menu>
            </Box>
          ) : (
            <Box />
          )
        }
      />

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DebugLogger title={"Member Redeemed Codes"} data={data} hidden={true} />
            <DebugLogger title={"Redeem codes"} data={redeemCodes} hidden={true} />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              options={redeemCodes}
              getOptionLabel={option => {
                return option.code;
              }}
              value={data.redeemCodeId}
              filterSelectedOptions
              isOptionEqualToValue={(opt, value) => {
                return opt.id === value.id;
              }}
              onChange={(event, newValue) => {
                setData({ ...data, redeemCodeId: newValue });
              }}
              renderInput={params => (
                <TextField {...params} required label={t("member.redeem_code")} />
              )}
            />
          </Grid>

          {data?.id && (
            <Grid item xs={12}>
              <Autocomplete
                options={statusList}
                getOptionLabel={option => {
                  return t(`member.redeemed_code_${option.name}`);
                }}
                value={data.state}
                filterSelectedOptions
                isOptionEqualToValue={(opt, value) => {
                  return opt.key === value.key;
                }}
                onChange={(event, newValue) => {
                  setData({ ...data, state: newValue });
                }}
                renderInput={params => <TextField {...params} label={t("layout.state")} />}
              />
            </Grid>
          )}

          <UpdaterInfo data={data} />
        </Grid>
      </CardContent>

      <CardActions sx={{ justifyContent: "space-between", px: 2 }}></CardActions>
    </Card>
  );
}
