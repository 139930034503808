import AuthProvider from "../components/AuthProvider";
import DashboardLayout from "../components/DashboardLayout";
import { getRolePageName } from "../helper/GlobalValueHelper";
import Create from "../views/role/Create";
import Edit from "../views/role/Edit";
import List from "../views/role/List";

const RoleRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/role",
      element: (
        <AuthProvider page={getRolePageName()} action={"view"}>
          <List />
        </AuthProvider>
      ),
    },
    {
      path: "role/create",
      element: (
        <AuthProvider page={getRolePageName()} action={"create"}>
          <Create />
        </AuthProvider>
      ),
    },
    {
      path: "role/:id",
      element: (
        <AuthProvider page={getRolePageName()} action={"update"}>
          <Edit />
        </AuthProvider>
      ),
    },
  ],
};

export default RoleRoute;
