import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

// helper
import { checkPermission } from "../../../helper/PermissionHelper";
import { pageName } from "../../../reducers/commonSlice";
import { user } from "../../../reducers/userSlice";

// icons
import AddIcon from "@mui/icons-material/Add";

// components
import DebugLogger from "../../../components/DebugLogger";
import TableSkeleton from "../../../components/TableSkeleton";

// api
import { PermissionModuleApi } from "../../../api/PermissionModuleApi";

export default function Permission() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(parseInt(searchParams.get("page")) || 1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState();
  const [total, setTotal] = useState(0);
  const [permissionModule, setPermissionModule] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const [haveCreatePermission, setHaveCreatePermission] = useState(false);
  const [haveUpdatePermission, setHaveUpdatePermission] = useState(false);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);
  const [colSpan, setColSpan] = useState(5);

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    if (!filter || filter.length <= 0) return;
    setPage(1);
    fetchData();
  }, [filter]);

  useEffect(() => {
    const createPermission = checkPermission(currentUser, currentPage, "create");
    setHaveCreatePermission(createPermission);

    const updatePermission = checkPermission(currentUser, currentPage, "update");
    setHaveUpdatePermission(updatePermission);
  }, [currentUser, currentPage]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await PermissionModuleApi.list(page, pageSize, filter);
      setPermissionModule(response.data);
      setTotal(response.total);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const viewDetail = id => {
    navigate(`/permission-module/${id}`);
  };

  const newUser = () => {
    navigate(`/permission-module/create`);
  };

  const onChangePage = value => {
    setPage(value);
    setSearchParams({ page: value });
  };

  return (
    <Container maxWidth="false">
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/settings"}>{t("menu.settings")}</Link>
        <Link to={"/role"}>{t("menu.role")}</Link>
        <Typography color="text.primary">{t("permission.permission_management")}</Typography>
      </Breadcrumbs>
      <Card variant="outlined">
        <CardHeader
          title={t("permission.permission_module_management")}
          action={
            haveCreatePermission && (
              <Button color="inherit" variant="outlined" startIcon={<AddIcon />} onClick={newUser}>
                {t("permission.new_permission_module")}
              </Button>
            )
          }
        />

        <CardContent>
          <Box xs={12}>
            <DebugLogger title={"Permission Module"} data={permissionModule} hidden={true} />
          </Box>
          <TableContainer elevation={0} component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width={"5%"}>{t("permission.id")}</TableCell>
                  <TableCell width={"10%"}>{t("permission.module")}</TableCell>
                  <TableCell width={"20%"}>{t("permission.action")}</TableCell>
                  <TableCell width={"10%"}>{t("layout.updated_by")}</TableCell>
                  <TableCell width={"10%"}>{t("layout.updated_at")}</TableCell>
                </TableRow>
              </TableHead>
              {isLoading ? (
                <TableSkeleton row={pageSize} colSpan={colSpan} />
              ) : (
                <TableBody>
                  {!permissionModule.length && (
                    <TableRow>
                      <TableCell colSpan={colSpan} align="center">
                        {t("layout.no_data")}
                      </TableCell>
                    </TableRow>
                  )}
                  {permissionModule.map(row => (
                    <TableRow
                      hover
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      onClick={() =>
                        haveUpdatePermission
                          ? viewDetail(row.id)
                          : toast.error(t("error.no_update_permission"))
                      }
                    >
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell>{t(`permission.${row.module}`)}</TableCell>
                      <TableCell>
                        {row.actions.map((item, index) => {
                          return (
                            t(`permission.${item}`) + (index !== row.actions.length - 1 ? ", " : "")
                          );
                        })}
                      </TableCell>
                      <TableCell>{row.updatedBy}</TableCell>
                      <TableCell>{row.updatedAt}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </CardContent>

        <CardActions sx={{ justifyContent: "space-between", px: 2 }}>
          <Typography variant="body2">
            {t("layout.total")}: {total}
          </Typography>
          <Stack spacing={2}>
            <Pagination
              page={page}
              count={Math.ceil(total / pageSize)}
              shape="rounded"
              onChange={(event, value) => {
                onChangePage(value);
              }}
            />
          </Stack>
        </CardActions>
      </Card>
    </Container>
  );
}
