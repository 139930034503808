import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactSwal from "../../helper/AlertHelper";
import { FormatHelper } from "../../helper/FormatHelper";
import DebugLogger from "../DebugLogger";
import UpdaterInfo from "../UpdaterInfo";
import ValidationTextField from "../ValidationTextField";
import { getPlaceHolderImage } from "../../helper/UnitsHelper";

export default function OrderBaseInfo({ order, setOrder, deleteOrder, haveDeletePermission }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showDeleteConfirm = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        deleteOrder();
      }
    });
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={order.orderNum}
        action={
          <Box display={"flex"} alignItems={"center"} height={50}>
            <Box display={"flex"} alignItems={"center"}>
              <FiberManualRecordIcon
                fontSize="8"
                color={order.status == "complete" ? "success" : "error"}
              />
              <Typography variant="caption">{t(`order.${order.status}`)}</Typography>
            </Box>
            {order.status != "refund" && order.status != "cancel" ? (
              <Box>
                <IconButton onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem disabled={!haveDeletePermission} onClick={showDeleteConfirm}>
                    <Typography color={"error"}>{t("layout.delete")}</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <Box p={1}></Box>
            )}
          </Box>
        }
      />

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DebugLogger title="Orders" data={order.orderItems} hidden={true}></DebugLogger>
          </Grid>

         <Grid item xs={12} md={3}>
            <Box display={"flex"} flexDirection={"column"}>
              <Typography variant="body2">{t("layout.email")}</Typography>
              <Typography variant="body2">{order.member ? order.member.email : "-"}</Typography>
            </Box>
          </Grid>

          <Grid item xs={0} md={1}>
            <Divider orientation="vertical" />
          </Grid>

          <Grid item xs={12} md={3}>
            <Box display={"flex"} flexDirection={"column"}>
              <Typography variant="body2">{t("report.order_date")}</Typography>
              <Typography variant="body2">{order.createdAt}</Typography>
            </Box>
          </Grid>

          <Grid item xs={0} md={1}>
            <Divider orientation="vertical" />
          </Grid>

          <Grid item xs={12} md={3}>
            <Box display={"flex"} flexDirection={"column"}>
              <Typography variant="body2">{t("menu.payment_method")}</Typography>
              <Typography variant="body2">
                {t(`payment_method.${FormatHelper.formatPaymentMethod(order.paymentMethod)}`)}
              </Typography>
            </Box>
          </Grid>

           {order.orderItems &&
            order.orderItems.length > 0 &&
            order.orderItems.map((item, index) => (
              <Grid item xs={12} key={index} sx={{ mt: 2, mb: 2 }}>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={"space-around"}
                >
                  <Stack>
                    <Box
                      component={"img"}
                      src={item.info.cover_image || getPlaceHolderImage()}
                      sx={{
                        objectFit: "contain",
                        maxWidth: "100%",
                        maxHeight: "150px",
                      }}
                    />
                  </Stack>
                  <Typography variant="body2">{item.info.slot_type ? t(`inGameGoods.${item.info.slot_type}_slot`) : item.info.title}</Typography>
                  <Typography variant="body2">{item.info.assets_id}</Typography>
                  <Typography variant="body2">x {item.qty}</Typography>
                  <Typography variant="body2">$ {item.info.price}</Typography>
                </Box>
              </Grid>
            ))}

          <Grid item xs={12}>
            <Box display={"flex"} flexDirection={"row"} justifyContent={"end"}>
              <Typography variant="h6">{t("layout.total")}:</Typography>
              <Typography variant="h6">$ {order.totalPrice}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <ValidationTextField
              name={"remark"}
              type={"text"}
              multiline={true}
              value={order.remark || ""}
              onChange={event => {
                setOrder({ ...order, remark: event.target.value });
              }}
              title={t("user.remark")}
            />
          </Grid>

          <UpdaterInfo data={order} />
        </Grid>
      </CardContent>

      <CardActions sx={{ justifyContent: "space-between", px: 2 }}></CardActions>
    </Card>
  );
}
