import { Translation } from "react-i18next";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import palette from "../styles/theme/palette";

const ReactSwal = withReactContent(Swal);

export default ReactSwal.mixin({
  confirmButtonColor: palette.primary.main,
  cancelButtonColor: palette.secondary.main,
  focusCancel: true,
  confirmButtonText: <Translation>{t => t("layout.ok")}</Translation>,
  cancelButtonText: <Translation>{t => t("layout.cancel")}</Translation>,
  reverseButtons: true,
  /* mui class name can not apply to sweetalert2 in uat and prod env */
  // buttonsStyling: false,
  // customClass: {
  //   confirmButton: 'MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-disableElevation MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-disableElevation css-l9eho6-MuiButtonBase-root-MuiButton-root',
  //   cancelButton: 'MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeLarge MuiButton-outlinedSizeLarge MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeLarge MuiButton-outlinedSizeLarge css-exmfpn-MuiButtonBase-root-MuiButton-root',
  // },
});
