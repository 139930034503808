import axios from "../helper/AxiosHelper";
import { statusList, reuseList } from "../helper/GlobalValueHelper";

const list = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "id.DESC",
  };
  const response = await axios.get(`/redeemCode/query?query=${JSON.stringify(query)}&fields=all`);
  const result = response.data.result;

  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        code: data.code,
        expireAt: data.expire_at,
        limit: data.limit,
        reuse: data.reuse,
        state: data.state,
        updatedAt: data.updated_at,
        updatedBy: data.updated_by_info ? data.updated_by_info.username : "",
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const details = async id => {
  const response = await axios.get(`redeemCode/show/${id}?fields=all`);
  const result = response.data.result;

  return {
    id: result.id,
    code: result.code,
    expireAt: result.expire_at,
    limit: !result.limit ? null : result.limit,
    gifts: result.gifts,
    reuse: reuseList.find(reuse => reuse.value == result.reuse),
    state: result.state ? statusList.find(status => status.value == result.state) : "null",
    remark: result.remark,
    createdAt: result.created_at,
    updatedAt: result.updated_at,
    createdBy: result.created_by_info ? result.created_by_info.username : "",
    updatedBy: result.updated_by_info ? result.updated_by_info.username : "",
  };
};

const create = async input => {
  const data = {
    code: input.code,
    expire_at: input.expireAt,
    limit: input.limit,
    gifts: input.gifts,
    reuse: input.reuse.value,
    state: input.state.value,
    remark: input.remark,
  };

  const response = await axios.post(`redeemCode/create`, data);

  return response.data.result.insert_id;
};

const update = async (id, input) => {
  const data = {
    code: input.code,
    expire_at: input.expireAt,
    limit: input.limit,
    gifts: input.gifts,
    reuse: input.reuse.value,
    state: input.state.value,
    remark: input.remark,
  };

  const response = await axios.post(`redeemCode/update/${id}`, data);
  return response.data.success;
};

const deleteData = async id => {
  const response = await axios.post(`redeemCode/delete/${id}`);
  return response.data.success;
};

export const RedeemCodeApi = {
  list,
  details,
  create,
  update,
  deleteData,
};
