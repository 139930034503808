import {
  Autocomplete,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

// helper
import ReactSwal from "../../helper/AlertHelper";
import { checkPermission } from "../../helper/PermissionHelper";
import { pageName } from "../../reducers/commonSlice";
import { user } from "../../reducers/userSlice";

// icons
import MoreVertIcon from "@mui/icons-material/MoreVert";

// components
import DebugLogger from "../DebugLogger";
import UpdaterInfo from "../UpdaterInfo";
import ValidationTextField from "../ValidationTextField";

// api
import { RoleApi } from "../../api/RoleApi";

export default function UserBaseInfo({ formik, onDelete, isCreate = false }) {
  const { t } = useTranslation();
  const [haveDeletePermission, setHaveDeletePermission] = useState(false);
  const [options, setOptions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const deletePermission = checkPermission(currentUser, currentPage, "delete");
    setHaveDeletePermission(deletePermission);
  }, [currentUser, currentPage]);

  const fetchData = async () => {
    try {
      const response = await RoleApi.list(1, -1, []);
      setOptions(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showDeleteConfirm = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        onDelete();
      }
    });
  };

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 5,
      }}
    >
      <CardHeader
        title={t("user.personal_information")}
        subheader={<Typography variant="caption">{t("user.manage_your_profile")}</Typography>}
        action={
          onDelete ? (
            <Box>
              <IconButton onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem disabled={!haveDeletePermission} onClick={showDeleteConfirm}>
                  <Typography color={"error"}>{t("layout.delete")}</Typography>
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <Box />
          )
        }
      />

      <CardContent>
        <Grid item xs={12}>
          <DebugLogger title={"User"} data={formik.values.roles} hidden={true} />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ValidationTextField
              name={"username"}
              type={"text"}
              required
              value={formik.values.username || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title={t("user.username")}
              errorText={formik.errors.username}
              touched={formik.touched.username}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <ValidationTextField
              name={"nameZh"}
              type={"text"}
              // required
              value={formik.values.nameZh}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title={t("user.name_zh")}
              errorText={formik.errors.nameZh}
              touched={formik.touched.nameZh}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <ValidationTextField
              name={"nameEn"}
              type={"text"}
              // required
              value={formik.values.nameEn}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title={t("user.name_en")}
              errorText={formik.errors.nameEn}
              touched={formik.touched.nameEn}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <ValidationTextField
              name={"email"}
              type={"text"}
              required
              value={formik.values.email || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title={t("user.email")}
              errorText={formik.errors.email}
              touched={formik.touched.email}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <ValidationTextField
              name={"phoneNumber"}
              // required
              value={formik.values.phoneNumber || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title={t("user.phone_number")}
              errorText={formik.errors.phoneNumber}
              touched={formik.touched.phoneNumber}
            />
          </Grid>

          <Grid item xs={12}>
            <ValidationTextField
              name={"password"}
              type={"password"}
              required={isCreate}
              value={formik.values.password || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title={t("user.password")}
              errorText={formik.errors.password}
              touched={formik.touched.password}
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              multiple
              options={options.map(role => ({
                label: role.name,
                value: role.id,
              }))}
              getOptionLabel={option => option.label}
              value={formik.values.roles || []}
              renderInput={params => (
                <TextField required {...params} key={params} label={t("user.roles")} />
              )}
              renderTags={(value, getTagProps) =>
                value &&
                value.map((option, index) => (
                  <Chip variant="outlined" label={option["label"]} {...getTagProps({ index })} key={option["label"]} />
                ))
              }
              onChange={(event, newValue) => {
                formik.setFieldValue("roles", newValue);
              }}
              filterSelectedOptions
              isOptionEqualToValue={(opt, value) => opt.value === value.value}
            />
          </Grid>

          <Grid item xs={12}>
            <ValidationTextField
              name={"remark"}
              type={"text"}
              multiline={true}
              value={formik.values.remark}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title={t("user.remark")}
              errorText={formik.errors.remark}
              touched={formik.touched.remark}
            />
          </Grid>

          <UpdaterInfo data={formik.values} />
        </Grid>
      </CardContent>

      <CardActions sx={{ justifyContent: "space-between", px: 2 }}></CardActions>
    </Card>
  );
}
