import { Box, Breadcrumbs, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

// helper
import { checkPermission } from "../../../helper/PermissionHelper";
import { pageName } from "../../../reducers/commonSlice";
import { user } from "../../../reducers/userSlice";

// components
import ActionFooter from "../../../components/ActionFooter";
import FullScreenLoading from "../../../components/FullScreenLoading";
import MemberCollectionsInfo from "../../../components/member/MemberCollectionsInfo";
import ReactSwal from "../../../helper/AlertHelper";

// api
import { CollectionApi } from "../../../api/CollectionApi";
import { MemberApi } from "../../../api/MemberApi";
import { OrderApi } from "../../../api/OrderApi";
import DebugLogger from "../../../components/DebugLogger";

export default function MemberCollectionsEdit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [memberCollection, setMemberCollection] = useState({
    memberId: null,
    refItem: null,
    mediaSrc: "",
    customCoverImage: "",
    customTitle: "",
    customDesc: "",
    state: {
      key: 1,
      name: "active",
      value: "active",
    },
  });
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const [page, setPage] = useState(-1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState();
  const [collections, setCollections] = useState([]);
  const [haveDeletePermission, setHaveDeletePermission] = useState(false);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);
  const [isSlot, setIsSlot] = useState(false);

  useEffect(() => {
    if (!id) {
      navigate(-1);
    }
    fetchData();
    fetchCollectionData();
  }, [page]);

  useEffect(() => {
    const deletePermission = checkPermission(currentUser, currentPage, "delete");

    setHaveDeletePermission(deletePermission);
  }, [currentUser, currentPage]);

  const fetchData = async () => {
    try {
      setFullScreenLoading(true);
      const response = await MemberApi.memberCollectionsDetails(id);
      setMemberCollection(response);

      if (response.refItem === null) {
        setIsSlot(true);
      }

      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const fetchCollectionData = async () => {
    try {
      setFullScreenLoading(true);
      const response = await CollectionApi.list(page, pageSize, filter);
      setFullScreenLoading(false);
      setCollections(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const validateForm = () => {
    let error = false;

    if (!memberCollection?.memberId) {
      toast.error(t("error.member_is_required"));
      error = true;
    }

    if (memberCollection.refItem) {
      if (memberCollection.customTitle.length == 0) {
        toast.error(t("error.title_is_required"));
        error = true;
      }
    }

    return error;
  };

  const update = async () => {
    try {
      let error = validateForm();

      if (error) {
        return;
      }

      setFullScreenLoading(true);
      const success = await MemberApi.memberCollectionsUpdate(id, memberCollection);
      if (success) {
        toast.success(t("layout.updated_successfully"));
        navigate(`/members/${memberCollection?.memberId?.id}`);
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const deleteMemberCollections = async () => {
    try {
      setFullScreenLoading(true);
      const success = await MemberApi.deleteMemberCollections(id);
      if (success) {
        toast.success(t("layout.deleted_successfully"));
        navigate(`/members/${memberCollection?.memberId?.id}`);
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  const onCheckout = async () => {
    let addCollection = false;
    if (memberCollection.refItem !== null) {
      addCollection = true;
    }

    if (isSlot && addCollection) {
      try {
        let error = validateForm();

        if (error) {
          return;
        }

        setFullScreenLoading(true);
        const response = await OrderApi.checkout(
          memberCollection,
          "collection",
        );
        if (response.success) {
          toast.success(t("layout.created_successfully"));
          navigate(`/members/${memberCollection?.memberId?.id}`);
        }
        setFullScreenLoading(false);
      } catch (error) {
        console.log(error);
      } finally {
        setFullScreenLoading(false);
      }
    } else {
      update();
    }
  };

  const showRedencePointConfirm = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.redence_point_confirm"),
      icon: "warning",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: t("layout.give_away"),
      denyButtonText: t("layout.not_give_away"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        update();
      } else if (result.isDenied) {
        onCheckout();
      }
    });
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/members"}>{t("menu.account")}</Link>
        <Link to={`/members/${memberCollection?.memberId?.id}`}>{t("menu.members")}</Link>
        <Typography color="text.primary">{t("layout.edit")}</Typography>
      </Breadcrumbs>
      <Box component="form">
        <MemberCollectionsInfo
          memberCollection={memberCollection}
          setMemberCollection={setMemberCollection}
          collections={collections}
          deleteMemberCollections={deleteMemberCollections}
          haveDeletePermission={haveDeletePermission}
        />
        <Box sx={{ my: 8 }} />
        <ActionFooter onSubmit={showRedencePointConfirm} onCancel={onCancel} />
      </Box>
    </Container>
  );
}
