import AuthProvider from "../components/AuthProvider";
import DashboardLayout from "../components/DashboardLayout";
import { getCollectionPageName } from "../helper/GlobalValueHelper";
import Create from "../views/collection/Create";
import Edit from "../views/collection/Edit";
import List from "../views/collection/List";

const CollectionRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/collection",
      element: (
        <AuthProvider page={getCollectionPageName()} action={"view"}>
          <List />
        </AuthProvider>
      ),
    },
    {
      path: "/collection/:id",
      element: (
        <AuthProvider page={getCollectionPageName()} action={"update"}>
          <Edit />
        </AuthProvider>
      ),
    },
    {
      path: "/collection/create",
      element: (
        <AuthProvider page={getCollectionPageName()} action={"create"}>
          <Create />
        </AuthProvider>
      ),
    },
  ],
};

export default CollectionRoute;
