import AuthProvider from "../components/AuthProvider";
import DashboardLayout from "../components/DashboardLayout";
import { getFilterPageName } from "../helper/GlobalValueHelper";
import Create from "../views/filter/Create";
import Edit from "../views/filter/Edit";
import List from "../views/filter/List";

const FilterRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/filter",
      element: (
        <AuthProvider page={getFilterPageName()} action={"view"}>
          <List />
        </AuthProvider>
      ),
    },
    {
      path: "/filter/:id",
      element: (
        <AuthProvider page={getFilterPageName()} action={"update"}>
          <Edit />
        </AuthProvider>
      ),
    },
    {
      path: "/filter/create",
      element: (
        <AuthProvider page={getFilterPageName()} action={"create"}>
          <Create />
        </AuthProvider>
      ),
    },
  ],
};

export default FilterRoute;
