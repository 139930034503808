import { useRoutes } from "react-router-dom";
import BackdropRoute from "./routes/BackdropRoute";
import CollectionRoute from "./routes/CollectionRoute";
import EffectRoute from "./routes/EffectRoute";
import HomeRoute from "./routes/HomeRoute";
import InGameGoodsRoute from "./routes/InGameGoodsRoute";
import MemberRoute from "./routes/MemberRoute";
import NotValidRoute from "./routes/NotValidRoute";
import OrderRoute from "./routes/OrderRoute";
import PageNotFoundRoute from "./routes/PageNotFound";
import PayemntRoute from "./routes/PaymentRoute";
import PermissionModuleRoute from "./routes/PermissionModuleRoute";
import RedeemCodeRoute from "./routes/RedeemCodeRoute";
import RoleRoute from "./routes/RoleRoute";
import SettingsRoute from "./routes/SettingsRoute";
import StageRoute from "./routes/StageRoute";
import UsersRoute from "./routes/UserRoute";
import FilterRoute from "./routes/FilterRoute";

export default function Router() {
  const routes = useRoutes([
    HomeRoute,
    RoleRoute,
    MemberRoute,
    NotValidRoute,
    PageNotFoundRoute,
    SettingsRoute,
    UsersRoute,
    PayemntRoute,
    OrderRoute,
    PermissionModuleRoute,
    EffectRoute,
    StageRoute,
    CollectionRoute,
    BackdropRoute,
    RedeemCodeRoute,
    InGameGoodsRoute,
    FilterRoute,
  ]);

  return routes;
}
