import axios from "../helper/AxiosHelper";
import { statusList } from "../helper/GlobalValueHelper";

const list = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "id.DESC",
  };
  const response = await axios.get(`/stage/query?query=${JSON.stringify(query)}&fields=all`);
  const result = response.data.result;

  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        coverImage: data.cover_image,
        title: data.title,
        assetsId: data.assets_id,
        price: parseInt(data.price),
        tags: data.tags || [],
        state: data.state,
        updatedAt: data.updated_at,
        updatedBy: data.updated_by_info ? data.updated_by_info.username : "",
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const fullList = async () => {
  const response = await axios.get(`/stage?fields=all`);
  const result = response.data.result;

  return result.map(data => {
    return {
      id: data.id,
      coverImage: data.cover_image,
      title: data.title,
      assetsId: data.assets_id,
      price: parseInt(data.price),
      tags: data.tags || [],
      state: data.state,
      updatedAt: data.updated_at,
      updatedBy: data.updated_by_info ? data.updated_by_info.username : "",
    };
  });
};

const details = async id => {
  const response = await axios.get(`stage/show/${id}?fields=all`);
  const result = response.data.result;

  return {
    id: result.id,
    coverImage: result.cover_image || "",
    title: result.title,
    assetsId: result.assets_id,
    mediaSrc: result.media_src || "",
    price: parseInt(result.price),
    tags: result.tags || [],
    description: result.description,
    state: result.state ? statusList.find(status => status.value == result.state) : "null",
    createdAt: result.created_at,
    updatedAt: result.updated_at,
    createdBy: result.created_by_info ? result.created_by_info.username : "",
    updatedBy: result.updated_by_info ? result.updated_by_info.username : "",
  };
};

const create = async input => {
  const formData = new FormData();
  const data = {
    cover_image: input.coverImage,
    media_src: input.mediaSrc,
    assets_id: input.assetsId,
    title: input.title,
    description: input.description,
    price: input.price,
    tags: input.tags || [],
    state: input.state ? input.state.value : undefined,
    upload_files: input.uploadFiles?.length || undefined,
    upload_asset_files: input.uploadAssetFiles?.length || undefined,
  };

  formData.append("data", JSON.stringify(data));

  if (input.uploadFiles) {
    input.uploadFiles.forEach((file, index) => {
      formData.append(`file_${index + 1}`, file);
    });
  }

  if (input.uploadAssetFiles) {
    input.uploadAssetFiles.forEach((file, index) => {
      formData.append(`asset_${index + 1}`, file);
    });
  }

  const response = await axios.post(`stage/create`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data.result.insert_id;
};

const update = async (id, input) => {
  const formData = new FormData();
  const data = {
    cover_image: input.coverImage || null,
    media_src: input.mediaSrc || null,
    assets_id: input.assetsId,
    title: input.title,
    description: input.description,
    price: input.price,
    tags: input.tags || [],
    state: input.state ? input.state.value : undefined,
    upload_files: input.uploadFiles?.length || undefined,
    upload_asset_files: input.uploadAssetFiles?.length || undefined,
  };

  formData.append("data", JSON.stringify(data));

  if (input.uploadFiles) {
    input.uploadFiles.forEach((file, index) => {
      formData.append(`file_${index + 1}`, file);
    });
  }

  if (input.uploadAssetFiles) {
    input.uploadAssetFiles.forEach((file, index) => {
      formData.append(`asset_${index + 1}`, file);
    });
  }

  const response = await axios.post(`stage/update/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data.success;
};

const deleteStage = async id => {
  const response = await axios.post(`stage/delete/${id}`);
  return response.data.success;
};

export const StageApi = {
  list,
  details,
  create,
  update,
  deleteStage,
  fullList,
};
