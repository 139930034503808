import { Box, Divider, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Wellcome() {
  const { t } = useTranslation();

  return (
    <Box>
      <Paper sx={{ p: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Box
            component={"img"}
            src="/assets/icons/logo_long.png"
            sx={{
              width: "12%",
            }}
          />
          <Typography variant="h5">{t("layout.welcome_header")}</Typography>
          <Divider flexItem />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            gap: 3,
            p: 5,
            ml: "8vw",
            mr: "8vw",
          }}
        >
          <Typography variant="body1">{t("layout.welcome_content_1")}</Typography>
          <Typography variant="body1">{t("layout.welcome_content_2")}</Typography>

          <Box sx={{
             ml: "2vw",
             mr: "2vw",
          }}>
            <Typography>
              ．{t("menu.account")}: {t("layout.welcome_content_4")}
            </Typography>
            <Typography>
              ．{t("menu.orders")}: {t("layout.welcome_content_5")}
            </Typography>
            <Typography>
              ．{t("menu.effects")}: {t("layout.welcome_content_6")}
            </Typography>
            <Typography>
              ．{t("menu.stage")}: {t("layout.welcome_content_7")}
            </Typography>
            <Typography>
              ．{t("menu.collection")}: {t("layout.welcome_content_8")}
            </Typography>
            <Typography>
              ．{t("menu.backdrop")}: {t("layout.welcome_content_9")}
            </Typography>
            <Typography>
              ．{t("menu.in_game_goods")}: {t("layout.welcome_content_10")}
            </Typography>
            <Typography>
              ．{t("menu.redeem_code")}: {t("layout.welcome_content_11")}
            </Typography>
            <Typography>
              ．{t("menu.settings")}: {t("layout.welcome_content_12")}
            </Typography>
          </Box>

          <Typography variant="body1">{t("layout.welcome_content_3")}</Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Typography variant="caption" color={"gray"}>
            {t("layout.version")} : {process.env.REACT_APP_VERSION}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
}
