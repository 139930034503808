import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import { Translation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// helper
import { AuthHelper } from "../helper/AuthHelper";
import { userId, username } from "../reducers/userSlice";

// icon
import AccountCircle from "@mui/icons-material/AccountCircle";

// api
import { UserApi } from "../api/UserApi";

export default function LanguageDropdown() {
  const [anchorEl, setAnchorEl] = useState(null);
  const currentUserId = useSelector(userId);
  const currentUsername = useSelector(username);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    AuthHelper.resetAuthCookies();
    await UserApi.logout();
    window.location.href = "/login";
    handleClose();
  };

  return (
    <Box component={"div"}>
      <Button onClick={handleMenu} color="inherit">
        <AccountCircle />
        {currentUsername}
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem component={Link} to={`/users/${currentUserId}`} onClick={handleClose}>
          <Translation>{t => <Typography>{t("layout.profile")}</Typography>}</Translation>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <Translation>{t => <Typography>{t("layout.logout")}</Typography>}</Translation>
        </MenuItem>
      </Menu>
    </Box>
  );
}
