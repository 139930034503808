import Login from "../views/Login";

const NotValidRoute = {
  children: [
    {
      path: "/login",
      element: <Login />,
    },
  ],
};

export default NotValidRoute;
