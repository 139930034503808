import { Box, Breadcrumbs, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

// helper
import { checkPermission } from "../../../helper/PermissionHelper";
import { pageName } from "../../../reducers/commonSlice";
import { user } from "../../../reducers/userSlice";

// components
import ActionFooter from "../../../components/ActionFooter";
import FullScreenLoading from "../../../components/FullScreenLoading";
import MemberRedeemedInfo from "../../../components/member/MemberRedeemedInfo";

// api
import { MemberApi } from "../../../api/MemberApi";
import { RedeemCodeApi } from "../../../api/RedeemCodeApi";

export default function MemberRedeemedCodeEdit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({
    memberId: null,
    redeemCodeId: null,
    state: {
      key: 1,
      name: "active",
      value: "active",
    },
  });
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const [page, setPage] = useState(-1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState();
  const [redeemCodes, setRedeemCodes] = useState([]);
  const [haveDeletePermission, setHaveDeletePermission] = useState(false);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);

  useEffect(() => {
    if (!id) {
      navigate(-1);
    }
    fetchData();
    fetchRedeemCodeData();
  }, [page]);

  useEffect(() => {
    const deletePermission = checkPermission(currentUser, currentPage, "delete");

    setHaveDeletePermission(deletePermission);
  }, [currentUser, currentPage]);

  const fetchData = async () => {
    try {
      setFullScreenLoading(true);
      const response = await MemberApi.memberRedeemedDetails(id);
      setData(response);
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const fetchRedeemCodeData = async () => {
    try {
      setFullScreenLoading(true);
      const response = await RedeemCodeApi.list(page, pageSize, filter);
      setFullScreenLoading(false);
      setRedeemCodes(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const validateForm = () => {
    let error = false;

    if (!data.memberId) {
      toast.error(t("error.member_is_required"));
      error = true;
    }

    if (!data.redeemCodeId) {
      toast.error(t("error.redeem_code_is_required"));
      error = true;
    }

    return error;
  };

  const update = async () => {
    try {
      let error = validateForm();

      if (error) {
        return;
      }

      setFullScreenLoading(true);
      const success = await MemberApi.memberRedeemedUpdate(id, data);
      if (success) {
        toast.success(t("layout.updated_successfully"));
        navigate(`/members/${data?.memberId?.id}`);
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const deleteData = async () => {
    try {
      setFullScreenLoading(true);
      const success = await MemberApi.deleteMemberRedeemedCode(id);
      if (success) {
        toast.success(t("layout.deleted_successfully"));
        navigate(`/members/${data?.memberId?.id}`);
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/members"}>{t("menu.account")}</Link>
        <Link to={`/members/${data?.memberId?.id}`}>{t("menu.members")}</Link>
        <Typography color="text.primary">{t("layout.edit")}</Typography>
      </Breadcrumbs>
      <Box component="form">
        <MemberRedeemedInfo
          data={data}
          setData={setData}
          redeemCodes={redeemCodes}
          deleteData={deleteData}
          haveDeletePermission={haveDeletePermission}
        />
        <Box sx={{ my: 8 }} />
        <ActionFooter onSubmit={update} onCancel={onCancel} />
      </Box>
    </Container>
  );
}
