import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

// components
import DebugLogger from "../DebugLogger";
import TableSkeleton from "../TableSkeleton";

// api
import { MemberApi } from "../../api/MemberApi";

export default function MemberPointHistory({ memberId }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState([{ field: "member_id", value: memberId, op: "eq" }]);
  const [searchStr, setSearchStr] = useState();
  const [total, setTotal] = useState(0);
  const [list, setList] = useState([]);
  const [colSpan, setColSpan] = useState(7);

  useEffect(() => {
    fetchMemberPointsHistoryList();
  }, [page]);

  const onChangePage = value => {
    setPage(value);
  };

  const fetchMemberPointsHistoryList = async () => {
    setIsLoading(true);
    const response = await MemberApi.memberPointsHistorylist(memberId, page);
    setList(response.data);
    setTotal(response.total);
    setIsLoading(false);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 5,
      }}
    >
      <CardHeader title={t("member.points_history")} />

      <CardContent>
        <DebugLogger title="Member Points History" data={list} hidden={true}></DebugLogger>
        <TableContainer elevation={0} component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Box variant="caption">{t("member.id")}</Box>
                </TableCell>
                <TableCell>
                  <Box variant="caption">{t("member.event")}</Box>
                </TableCell>
                <TableCell>
                  <Box variant="caption">{t("member.qty_change")}</Box>
                </TableCell>
                <TableCell>
                  <Box variant="caption">{t("member.remark")}</Box>
                </TableCell>
                <TableCell>
                  <Box variant="caption">{t("member.balance")}</Box>
                </TableCell>
                <TableCell>
                  <Box variant="caption">{t("member.created_by")}</Box>
                </TableCell>
                <TableCell>
                  <Box variant="caption">{t("member.created_at")}</Box>
                </TableCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableSkeleton row={pageSize} colSpan={colSpan} />
            ) : (
              <TableBody>
                {!list.length && (
                  <TableRow>
                    <TableCell colSpan={colSpan} align="center">
                      {t("layout.no_data")}
                    </TableCell>
                  </TableRow>
                )}
                {list.map(row => (
                  <TableRow
                    hover
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{t("member.event_" + row.event)}</TableCell>
                    <TableCell>
                      {row.event === "buy" || row.event === "minus" ? "-" : "+"}
                      {row.qtyChange}
                    </TableCell>
                    <TableCell>{row.remark}</TableCell>
                    <TableCell>{row.balance}</TableCell>
                    <TableCell>
                      {row.event === "buy" || row.event === "join"
                        ? t("member.system")
                        : row.createdBy}
                    </TableCell>
                    <TableCell>{row.createdAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box sx={{ display: "flex", justifyContent: "space-between", px: 2 }}>
          <Typography variant="body2">
            {t("layout.total")}: {total}
          </Typography>
          <Stack spacing={2}>
            <Pagination
              page={page}
              count={Math.ceil(total / pageSize)}
              shape="rounded"
              onChange={(event, value) => {
                onChangePage(value);
              }}
            />
          </Stack>
        </Box>
      </CardContent>

      <CardActions sx={{ justifyContent: "space-between", px: 2 }}></CardActions>
    </Card>
  );
}
