import axios from "../helper/AxiosHelper";
import { filterTypeList, statusList } from "../helper/GlobalValueHelper";

const list = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "id.DESC",
  };
  const response = await axios.get(`/filter/query?query=${JSON.stringify(query)}&fields=all`);
  const result = response.data.result;

  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        type: data.type,
        subType: data.sub_type,
        tag: data.tag,
        state: data.state,
        updatedAt: data.updated_at,
        updatedBy: data.updated_by_info ? data.updated_by_info.username : "",
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const details = async id => {
  const response = await axios.get(`filter/show/${id}?fields=all`);
  const result = response.data.result;

  return {
    id: result.id,
    type: result.type ? filterTypeList.find(type => type.value == result.type) : null,
    subType: result.sub_type,
    tag: result.tag,
    state: result.state ? statusList.find(status => status.value == result.state) : null,
    createdAt: result.created_at,
    updatedAt: result.updated_at,
    createdBy: result.created_by_info ? result.created_by_info.username : "",
    updatedBy: result.updated_by_info ? result.updated_by_info.username : "",
  };
};

const create = async input => {
  const data = {
    type: input.type.value,
    sub_type: input.subType,
    tag: input.tag,
  };

  const response = await axios.post(`filter/create`, data);

  return response.data.result.insert_id;
};

const update = async (id, input) => {
  const data = {
    type: input.type.value,
    sub_type: input.subType,
    tag: input.tag,
  };

  const response = await axios.post(`filter/update/${id}`, data);

  return response.data.success;
};

const deleteFilter = async id => {
  const response = await axios.post(`filter/delete/${id}`);
  return response.data.success;
};

export const FilterApi = {
  list,
  details,
  create,
  update,
  deleteFilter,
};
