const palette = {
  type: "light",
  black: {
    main: "#000000",
  },
  white: {
    main: "#ffffff",
  },
  primary: {
    main: "#4ca137",
    hover: "#7D1AFF",
    lighten: "#D4B2FF",
    lightenHover: "#B780FF",
    lightenSecondary: "#F2E8FF",
  },
  secondary: {
    main: "#9D9B9F",
    contrastText: "#FFFFFF",
  },
  accent: {
    main: "#4f64a3",
  },
  error: {
    main: "#D60000",
  },
  warning: {
    main: "#FFAE0C",
  },
  success: {
    main: "#6F9F27",
  },
  info: {
    main: "#0C99FF",
  },
  background: {
    default: "#FFFFFF",
    paper: "#FFF",
    bg1: "#FFF",
    bg2: "#FAFAFA",
    bg3: "#FBF7FF",
    bg4: "#F0E0FF",
    bg5: "#F2FAFB",
    bg6: "#F0E6DB",
    bg7: "#FFE5E5",
    error: "#FFE5E5",
  },
  text: {
    primary: "rgba(17, 17, 17, 0.88)",
    secondary: "#424242",
    disabled: "rgba(17, 17, 17, 0.24)",
    highlight: "#111",
    reversed: "#FFFFFF",
  },
};

export default palette;
