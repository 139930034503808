import { Box, Breadcrumbs, Container, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// components
import ActionFooter from "../../components/ActionFooter";
import FullScreenLoading from "../../components/FullScreenLoading";
import StageInfo from "../../components/stage/StageInfo";

// api
import { StageApi } from "../../api/StageApi";

export default function StageCreate() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [stage, setStage] = useState({
    coverImage: "",
    mediaSrc: "",
    assetsId: "",
    title: "",
    description: "",
    price: 0,
    tags: [],
    state: {
      key: 1,
      name: "active",
      value: "active",
    },
  });
  const [fullScreenLoading, setFullScreenLoading] = useState(false);

  const validateForm = () => {
    let error = false;

    if (stage.assetsId.length == 0) {
      toast.error(t("error.assets_id_is_required"));
      error = true;
    }

    if (stage.title.length == 0) {
      toast.error(t("error.name_is_required"));
      error = true;
    }

    if (stage.price.length == 0) {
      toast.error(t("error.price_is_required"));
      error = true;
    }

    return error;
  };

  const onSubmit = async () => {
    try {
      let error = validateForm();

      if (error) {
        return;
      }

      setFullScreenLoading(true);
      const id = await StageApi.create(stage);
      if (id) {
        toast.success(t("layout.created_successfully"));
        navigate(`/stage`);
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/stage"}>{t("menu.stage")}</Link>
        <Typography color="text.primary">{t("layout.create")}</Typography>
      </Breadcrumbs>
      <Box component="form">
        <StageInfo stage={stage} setStage={setStage} />
        <Box sx={{ my: 8 }} />
        <ActionFooter onSubmit={onSubmit} onCancel={onCancel} />
      </Box>
    </Container>
  );
}
