import { Box, Breadcrumbs, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

// components
import ActionFooter from "../../components/ActionFooter";
import FullScreenLoading from "../../components/FullScreenLoading";
import PaymentMethodInfo from "../../components/payment/PaymentMethodInfo";

// api
import { PaymentApi } from "../../api/PaymentApi";

export default function PaymentMethodEdit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [paymentMethod, setPaymentMethod] = useState({
    name: "",
    // iconFile: "",
    icon: "/assets/icons/cash.svg", // hardcode for now
  });
  const [fullScreenLoading, setFullScreenLoading] = useState(false);

  useEffect(() => {
    if (!id) {
      navigate(-1);
    }
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setFullScreenLoading(true);
      const response = await PaymentApi.details(id);
      setPaymentMethod(response);
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onSubmit = async () => {
    try {
      setFullScreenLoading(true);

      if (!paymentMethod.name) {
        toast.error(t("error.please_enter_all_required_fields"));
        setFullScreenLoading(false);
        return;
      }

      const success = await PaymentApi.update(id, paymentMethod);
      if (id) {
        toast.success(t("layout.updated_successfully"));
        fetchData();
      }

      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  const deletePaymentMethod = async () => {
    try {
      setFullScreenLoading(true);
      const success = await PaymentApi.deletePaymentMethod(id);
      if (success) {
        toast.success(t("layout.deleted_successfully"));
        navigate("/payment-method");
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/payment-method"}>{t("layout.payment_method")}</Link>
        <Typography color="text.primary">{t("layout.edit")}</Typography>
      </Breadcrumbs>
      <Box component="form">
        <PaymentMethodInfo
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          deletePaymentMethod={deletePaymentMethod}
        />
      </Box>
      <ActionFooter onCancel={onCancel} onSubmit={onSubmit} />
    </Container>
  );
}
