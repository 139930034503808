import { Breadcrumbs, Container, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

// components
import ActionFooter from "../../../components/ActionFooter";
import FullScreenLoading from "../../../components/FullScreenLoading";
import PermissionModuleBaseInfo from "../../../components/permission/PermissionModuleBaseInfo";

// api
import { PermissionModuleApi } from "../../../api/PermissionModuleApi";

// validation schema
const validationSchema = Yup.object().shape({
  module: Yup.string().required(
    <Translation>{t => t("error.module_is_required")}</Translation>,
  ),
});

export default function RoleCreate() {
  const { t } = useTranslation();
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      module: "",
      actions: [],
    },
    validateOnMount: true,
    validationSchema: validationSchema,
  });

  const onSubmit = async () => {
    try {
      formik.setTouched({
        module: true,
      });

      formik.validateForm();

      if (!formik.isValid) {
        return;
      }

      if (formik.values.actions.length === 0 || !formik.values.actions) {
        toast.error(t("error.please_at_least_input_one_action"));
        return;
      }

      const module = {
        ...formik.values,
      };

      setFullScreenLoading(true);

      const id = await PermissionModuleApi.create(module);
      if (id) {
        toast.success(t("layout.created_successfully"));
        navigate(`/permission-module`);
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/settings"}>{t("menu.settings")}</Link>
        <Link to={"/role"}>{t("menu.role")}</Link>
        <Link to={"/permission-module"}>{t("permission.permission_management")}</Link>
        <Typography color="text.primary">{t("layout.create")}</Typography>
      </Breadcrumbs>
      <PermissionModuleBaseInfo formik={formik} />
      <ActionFooter onSubmit={onSubmit} onCancel={onCancel} />
    </Container>
  );
}
