import { Breadcrumbs, Container, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

// helper
import { checkPermission } from "../../helper/PermissionHelper";
import { pageName } from "../../reducers/commonSlice";
import { user } from "../../reducers/userSlice";

// components
import ActionFooter from "../../components/ActionFooter";
import MemberSkeleton from "../../components/DetailsSkeleton";
import FullScreenLoading from "../../components/FullScreenLoading";
import MemberBackdropList from "../../components/member/MemberBackdropList";
import MemberBaseInfo from "../../components/member/MemberBaseInfo";
import MemberCollectionSlotList from "../../components/member/MemberCollectionSlotList";
import MemberEffectList from "../../components/member/MemberEffectList";
import MemberPointHistory from "../../components/member/MemberPointHistory";
import MemberRedeemedList from "../../components/member/MemberRedeemedList";
import MemberScenesSlotList from "../../components/member/MemberScenesSlotList";
import MemberStageList from "../../components/member/MemberStageList";

// api
import { MemberApi } from "../../api/MemberApi";
import { UserApi } from "../../api/UserApi";

// validation schema
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required(<Translation>{t => t("error.email_is_required")}</Translation>)
    .email(<Translation>{t => t("error.email_invalid_format")}</Translation>),
  points: Yup.number().test(
    "isPostive?",
    <Translation>{t => t("error.invalid_format")}</Translation>,
    val => val >= 0,
  ),
  password: Yup.string()
    .min(8, <Translation>{t => t("error.passowrd_length_min_8_characters")}</Translation>)
    .matches(/((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/, {
      excludeEmptyString: false,
      message: <Translation>{t => t("error.password_letter")}</Translation>,
    }),
});

export default function MemberEdit({ onClose, onUpdated }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [userList, setUserList] = useState([]);
  const [roomZoneList, setRoomZoneList] = useState([]);
  const formik = useFormik({
    initialValues: {
      id: null,
      email: "",
      password: "",
      lang: {
        key: 1,
        name: "en",
        value: "en",
      },
      role: {
        key: 2,
        name: "user",
        value: "user",
      },
      points: 0,
      state: {
        key: 1,
        name: "active",
        value: "active",
      },
    },
    validateOnMount: true,
    validationSchema: validationSchema,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  let memberId = id;
  const [haveCreatePermission, setHaveCreatePermission] = useState(false);
  const [haveUpdatePermission, setHaveUpdatePermission] = useState(false);
  const [haveDeletePermission, setHaveDeletePermission] = useState(false);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);

  useEffect(() => {
    if (!memberId) {
      navigate(-1);
    }
    fetchData();
    fetchUserList();
  }, []);

  useEffect(() => {
    const createPermission = checkPermission(currentUser, currentPage, "create");
    setHaveCreatePermission(createPermission);

    const updatePermission = checkPermission(currentUser, currentPage, "update");
    setHaveUpdatePermission(updatePermission);

    const deletePermission = checkPermission(currentUser, currentPage, "delete");
    setHaveDeletePermission(deletePermission);
  }, [currentUser, currentPage]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await MemberApi.details(memberId);
      formik.setValues(response);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserList = async () => {
    try {
      const users = await UserApi.queryAllusers();

      if (users.data) {
        setUserList(users.data);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const update = async () => {
    try {
      formik.setTouched({
        email: true,
        password: true,
        points: true,
      });

      formik.validateForm();

      if (!formik.isValid) {
        return;
      }

      const member = {
        ...formik.values,
      };

      setFullScreenLoading(true);

      const success = await MemberApi.update(memberId, member);

      if (success) {
        toast.success(t("layout.updated_successfully"));
        fetchData();
      }

      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  const onDelete = async () => {
    try {
      setFullScreenLoading(true);
      const success = await MemberApi.deleteMember(id);
      if (success) {
        toast.success(t("layout.deleted_successfully"));
        navigate("/members");
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onReset = async () => {
    try {
      setFullScreenLoading(true);
      const success = await MemberApi.resetMember(id);
      if (success) {
        toast.success(t("layout.deleted_successfully"));
        navigate("/members");
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  if (isLoading) {
    return <MemberSkeleton />;
  }

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/members"}>{t("menu.account")}</Link>
        <Typography color="text.primary">{t("layout.edit")}</Typography>
      </Breadcrumbs>
      <MemberBaseInfo
        formik={formik}
        onDelete={onDelete}
        onReset={onReset}
        haveDeletePermission={haveDeletePermission}
        isCreate={false}
        userList={userList}
        roomZoneList={roomZoneList}
        fetchData={fetchData}
      />
      <MemberEffectList
        memberId={memberId}
        haveCreatePermission={haveCreatePermission}
        haveUpdatePermission={haveUpdatePermission}
      />
      <MemberStageList
        memberId={memberId}
        haveCreatePermission={haveCreatePermission}
        haveUpdatePermission={haveUpdatePermission}
      />
      <MemberCollectionSlotList
        memberId={memberId}
        haveCreatePermission={haveCreatePermission}
        haveUpdatePermission={haveUpdatePermission}
      />
      <MemberBackdropList
        memberId={memberId}
        haveCreatePermission={haveCreatePermission}
        haveUpdatePermission={haveUpdatePermission}
      />

      <MemberScenesSlotList
        memberId={memberId}
        haveCreatePermission={haveCreatePermission}
        haveUpdatePermission={haveUpdatePermission}
      />
      <MemberRedeemedList
        memberId={memberId}
        haveCreatePermission={haveCreatePermission}
        haveUpdatePermission={haveUpdatePermission}
      />
      <MemberPointHistory memberId={memberId} />
      {/* <MemberOrderHistory memberId={memberId} /> */}
      <ActionFooter onSubmit={update} onCancel={onCancel} />
    </Container>
  );
}
