import { createTheme } from "@mui/material";
const theme = createTheme();

const Element = {
  defaultProps: {},
  styleOverrides: {
    root: {
      width: "auto",
    },
    viewTransitionContainer: {
      "& > div > div": {
        justifyContent: "space-between !important",
        paddingLeft: theme.spacing(1.25),
        paddingRight: theme.spacing(1.25),
      },
      "& div[role=row]": {
        paddingLeft: theme.spacing(1.25),
        paddingRight: theme.spacing(1.25),
        justifyContent: "space-between !important",
      },
    },
  },
};

export default Element;
