import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function AppVersion() {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="caption" color={"gray"}>
        {t("layout.version")} : {process.env.REACT_APP_VERSION}
      </Typography>
    </Box>
  );
}
