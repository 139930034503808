import AuthProvider from "../components/AuthProvider";
import DashboardLayout from "../components/DashboardLayout";
import { getInGameGoodsPageName } from "../helper/GlobalValueHelper";
import Create from "../views/inGameGoods/Create";
import Edit from "../views/inGameGoods/Edit";
import List from "../views/inGameGoods/List";

const InGameGoodsRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/in-game-goods",
      element: (
        <AuthProvider page={getInGameGoodsPageName()} action={"view"}>
          <List />
        </AuthProvider>
      ),
    },
    {
      path: "/in-game-goods/:id",
      element: (
        <AuthProvider page={getInGameGoodsPageName()} action={"update"}>
          <Edit />
        </AuthProvider>
      ),
    },
    {
      path: "/in-game-goods/create",
      element: (
        <AuthProvider page={getInGameGoodsPageName()} action={"create"}>
          <Create />
        </AuthProvider>
      ),
    },
  ],
};

export default InGameGoodsRoute;
