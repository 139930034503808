import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

// helper
import { AuthHelper } from "../helper/AuthHelper";
import { checkPagePermission } from "../helper/PermissionHelper";
import { updateActionState, updatePageState } from "../reducers/commonSlice";
import { updateJwtState, updateUserInfo } from "../reducers/userSlice";

// api
import { UserApi } from "../api/UserApi";

const AuthProvider = ({ children, page, action }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);

  useEffect(() => {
    dispatch(updatePageState(page));
    dispatch(updateActionState(action));

    const hasLogin = AuthHelper.hasLogin();

    if (!hasLogin) {
      setIsAuthenticated(false);
      return;
    } else {
      setIsAuthenticated(true);
      const user = AuthHelper.getUserCookies();
      const jwt = AuthHelper.getAccessTokenCookies();
      dispatch(updateJwtState(jwt));

      fetchUser(user.userId);
    }
  }, []);

  useEffect(() => {
    if (!user) return;
    chekcUser();
    // checkDeviceLock(user);
    checkPagePermission(user, page, action, navigate);
    dispatch(updatePageState(page));
    dispatch(updateActionState(action));
  }, [location]);

  const fetchUser = async id => {
    setIsLoading(true);
    try {
      const response = await UserApi.details(id);
      if (response) {
        setUser(response);
        dispatch(updateUserInfo(response));
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(true);
      }
    } catch (err) {
      console.error(err);
      setIsAuthenticated(false);
    }
    setIsLoading(false);
  };

  const chekcUser = () => {
    var user = AuthHelper.getUserCookies();
    if (!user) {
      setIsAuthenticated(false);
      return;
    }
  };

  if (!isAuthenticated) {
    return <Navigate to={`/login?redirect=${location.pathname}`} state={{ from: location }} />;
  }
  return children;
};

export default AuthProvider;
