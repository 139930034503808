import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Autocomplete,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactSwal from "../../helper/AlertHelper";
import {
  statusList,
  inGameGoodsTypeList,
  inGameGoodsSlotTypeList,
} from "../../helper/GlobalValueHelper";
import DebugLogger from "../DebugLogger";
import UpdaterInfo from "../UpdaterInfo";

export default function InGameGoodsInfo({ data, setData, deleteData, haveDeletePermission }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showDeleteConfirm = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        deleteData();
      }
    });
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={data.id ? t("inGameGoods.edit_in_game_goods") : t("inGameGoods.new_in_game_goods")}
        action={
          deleteData && haveDeletePermission ? (
            <Box>
              <IconButton onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={showDeleteConfirm}>
                  <Typography color={"error"}>{t("layout.delete")}</Typography>
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <Box />
          )
        }
      />

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DebugLogger title={"In Game Goods"} data={data} hidden={true} />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              options={inGameGoodsTypeList}
              getOptionLabel={option => {
                return t(`inGameGoods.${option.name}`);
              }}
              value={data.type}
              filterSelectedOptions
              isOptionEqualToValue={(opt, value) => {
                return opt.key === value.key;
              }}
              onChange={(event, newValue) => {
                setData({ ...data, type: newValue, slotType: {
                  key: 1,
                  name: "collection",
                  value: "collection",
                }});
              }}
              renderInput={params => <TextField {...params} label={t("layout.state")} />}
            />
          </Grid>

          {data.type.value === "slot" && (
            <Grid item xs={12}>
              <Autocomplete
                options={inGameGoodsSlotTypeList}
                getOptionLabel={option => {
                  return t(`inGameGoods.${option.name}_slot`);
                }}
                value={data.slotType}
                filterSelectedOptions
                isOptionEqualToValue={(opt, value) => {
                  return opt.key === value.key;
                }}
                onChange={(event, newValue) => {
                  setData({ ...data, slotType: newValue });
                }}
                renderInput={params => <TextField {...params} label={t("inGameGoods.slot")} />}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              value={data.title}
              onChange={event => setData({ ...data, title: event.target.value })}
              required
              fullWidth
              autoFocus
              label={t("inGameGoods.title")}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              type="number"
              value={data.price}
              onChange={event => setData({ ...data, price: event.target.value })}
              required
              fullWidth
              autoFocus
              label={t("inGameGoods.price")}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Autocomplete
              options={statusList}
              getOptionLabel={option => {
                return t(`inGameGoods.${option.name}`);
              }}
              value={data.state}
              filterSelectedOptions
              isOptionEqualToValue={(opt, value) => {
                return opt.key === value.key;
              }}
              onChange={(event, newValue) => {
                setData({ ...data, state: newValue });
              }}
              renderInput={params => <TextField {...params} label={t("layout.state")} />}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t("stage.description")}
              multiline
              value={data.description}
              onChange={event => {
                setData({ ...data, description: event.target.value });
              }}
              rows={4}
            />
          </Grid>

          <UpdaterInfo data={data} />
        </Grid>
      </CardContent>

      <CardActions sx={{ justifyContent: "space-between", px: 2 }}></CardActions>
    </Card>
  );
}
