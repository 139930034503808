import AuthProvider from "../components/AuthProvider";
import DashboardLayout from "../components/DashboardLayout";
import { getRedeemCodePageName } from "../helper/GlobalValueHelper";
import Create from "../views/redeemCode/Create";
import Edit from "../views/redeemCode/Edit";
import List from "../views/redeemCode/List";

const RedeemCodeRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/redeem-codes",
      element: (
        <AuthProvider page={getRedeemCodePageName()} action={"view"}>
          <List />
        </AuthProvider>
      ),
    },
    {
      path: "/redeem-codes/:id",
      element: (
        <AuthProvider page={getRedeemCodePageName()} action={"update"}>
          <Edit />
        </AuthProvider>
      ),
    },
    {
      path: "/redeem-codes/create",
      element: (
        <AuthProvider page={getRedeemCodePageName()} action={"create"}>
          <Create />
        </AuthProvider>
      ),
    },
  ],
};

export default RedeemCodeRoute;
