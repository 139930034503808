export const getWellcomePageName = () => {
  return "welcome";
};
export const getLandingPageName = () => {
  return "landing";
};
export const getMemberPageName = () => {
  return "member";
};
export const getRolePageName = () => {
  return "role";
};
export const getInGameGoodsPageName = () => {
  return "inGameGoods";
};
export const getRedeemCodePageName = () => {
  return "redeemCode";
};
export const getPermissionModulePageName = () => {
  return "permissionModule";
};
export const getSettingsPageName = () => {
  return "settings";
};
export const getEffectsPageName = () => {
  return "effects";
};
export const getStagePageName = () => {
  return "stage";
};
export const getCollectionPageName = () => {
  return "collection";
};
export const getBackdropPageName = () => {
  return "backdrop";
};
export const getUserPageName = () => {
  return "user";
};
export const getPaymentMethodPageName = () => {
  return "paymentMethod";
};
export const getNormalProductType = () => {
  return "normal";
};
export const getServiceProductType = () => {
  return "service";
};
export const getOrderPageName = () => {
  return "order";
};
export const getReportPageName = () => {
  return "report";
};
export const getFilterPageName = () => {
  return "filter";
};
export const maxUploadFileMb = 100;
export const genderList = [
  {
    key: "M",
    value: "M",
  },
  {
    key: "F",
    value: "F",
  },
];

export const langList = [
  {
    key: 1,
    name: "en",
    value: "en",
  },
  {
    key: 2,
    name: "zh",
    value: "zh",
  },
  {
    key: 3,
    name: "zh-cn",
    value: "zh-cn",
  },
];

export const roleList = [
  {
    key: 1,
    name: "admin",
    value: "admin",
  },
  {
    key: 2,
    name: "user",
    value: "user",
  }
];

export const stateList = [
  {
    key: 1,
    name: "active",
    value: "active",
  },
  {
    key: 2,
    name: "inactive",
    value: "inactive",
  },
  {
    key: 3,
    name: "banned",
    value: "banned",
  }
];

export const reuseList = [
  {
    key: 1,
    name: "reuse",
    value: 1,
  },
  {
    key: 2,
    name: "unreuse",
    value: 0,
  }
];

export const statusList = [
  {
    key: 1,
    name: "active",
    value: "active",
  },
  {
    key: 2,
    name: "inactive",
    value: "inactive",
  }
];

export const filterTypeList = [
  {
    key: 1,
    name: "collection",
    value: "collection",
  },
  {
    key: 2,
    name: "effect",
    value: "effect",
  },
  {
    key: 3,
    name: "backdrop",
    value: "backdrop",
  },
  {
    key: 4,
    name: "stage",
    value: "stage",
  }
];

export const inGameGoodsTypeList = [
  {
    key: 1,
    name: "token",
    value: "token",
  },
  {
    key: 2,
    name: "slot",
    value: "slot",
  }
];

export const inGameGoodsSlotTypeList = [
  {
    key: 1,
    name: "collection",
    value: "collection",
  },
  {
    key: 2,
    name: "scene",
    value: "scene",
  }
];

export const paymentMethodDefaultType = "system";
export const paymentMethodCustomType = "custom";
// page name end
