import { Box, Card, CardContent, CardHeader, Grid, Skeleton } from "@mui/material";
import { useEffect } from "react";

export default function PaymentSkeleton() {
  useEffect(() => {}, []);

  return (
    <Box>
      <Card variant="outlined">
        <CardHeader
          subheader={<Skeleton height={50} width={"30%"} animation="wave" />}
        ></CardHeader>
        <CardContent>
          <Grid container spacing={2}>
            {Array.from(new Array(8)).map((item, index) => (
              <Grid key={index} item xs={3}>
                <Skeleton height={100} animation="wave" />
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}
