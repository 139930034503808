import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

// icons
import AddIcon from "@mui/icons-material/Add";

// components
import { getPlaceHolderImage } from "../../helper/UnitsHelper";
import DebugLogger from "../DebugLogger";
import TableSkeleton from "../TableSkeleton";

// api
import { toast } from "react-toastify";
import { MemberApi } from "../../api/MemberApi";

export default function MemberEffectsSlotList({
  memberId,
  haveCreatePermission,
  haveUpdatePermission,
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState([{ field: "member_id", value: memberId, op: "eq" }]);
  const [searchStr, setSearchStr] = useState();
  const [total, setTotal] = useState(0);
  const [list, setList] = useState([]);
  const [colSpan, setColSpan] = useState(4);
  const navigate = useNavigate();

  useEffect(() => {
    fetchMemberEffectsList();
  }, [page]);

  const onChangePage = value => {
    setPage(value);
  };

  const fetchMemberEffectsList = async () => {
    setIsLoading(true);
    const response = await MemberApi.memberEffectsList(page, pageSize, filter);
    setList(response.data);
    setTotal(response.total);
    setIsLoading(false);
  };

  const viewDetail = id => {
    navigate(`/members/effects/${id}`);
  };

  const addMemberEffects = () => {
    navigate(`/members/effects/create/${memberId}`);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 5,
      }}
    >
      <CardHeader
        title={t("member.effects")}
        action={
          haveCreatePermission && (
            <Button
              color="inherit"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={addMemberEffects}
            >
              <Translation>{t => t("member.new_member_effects")}</Translation>
            </Button>
          )
        }
      />

      <CardContent>
        <DebugLogger title="Member Effects Slot" data={list} hidden={true}></DebugLogger>
        <TableContainer elevation={0} component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width={"5%"}>{t("member.effects_id")}</TableCell>
                <TableCell width={"5%"}>{t("member.effects_cover_image")}</TableCell>
                <TableCell width={"10%"}>{t("member.state")}</TableCell>
                <TableCell width={"10%"}>{t("layout.updated_at")}</TableCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableSkeleton row={pageSize} colSpan={colSpan} />
            ) : (
              <TableBody>
                {!list.length && (
                  <TableRow>
                    <TableCell colSpan={colSpan} align="center">
                      {t("layout.no_data")}
                    </TableCell>
                  </TableRow>
                )}
                {list.map((row, index) => (
                  <TableRow
                    hover
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    onClick={() =>
                      haveUpdatePermission
                        ? viewDetail(row.id)
                        : toast.error(t("error.no_update_permission"))
                    }
                  >
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" spacing={2}>
                        <Avatar
                          variant="rounded"
                          sx={{ border: "1px solid #0000001f" }}
                          alt={`effects_cover_image_${index}`}
                          src={row?.refItem?.cover_image || getPlaceHolderImage()}
                        />
                      </Stack>
                    </TableCell>
                    <TableCell>{t(`member.collections_${row.state.value}`)}</TableCell>
                    <TableCell>{row.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box sx={{ display: "flex", justifyContent: "space-between", px: 2 }}>
          <Typography variant="body2">
            {t("layout.total")}: {total}
          </Typography>
          <Stack spacing={2}>
            <Pagination
              page={page}
              count={Math.ceil(total / pageSize)}
              shape="rounded"
              onChange={(event, value) => {
                onChangePage(value);
              }}
            />
          </Stack>
        </Box>
      </CardContent>

      <CardActions sx={{ justifyContent: "space-between", px: 2 }}></CardActions>
    </Card>
  );
}
