import axios from "../helper/AxiosHelper";
import { inGameGoodsApi } from "./inGameGoodsApi";

const list = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "id.DESC",
  };
  const response = await axios.get(`/order/query?query=${JSON.stringify(query)}&fields=all`);
  const result = response.data.result;

  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        orderNum: data.order_num,
        memberId: data.member_id_info ? data.member_id_info : null,
        orderItems: data.order_items,
        totalPrice: data.total_price,
        paymentMethod: data.payment_method,
        status: data.status,
        remark: data.remark,
        createdAt: data.created_at,
        createdBy: data.created_by,
        updatedAt: data.updated_at,
        updatedBy: data.updated_by,
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const details = async id => {
  const response = await axios.get(`order/show/${id}?fields=all`);
  const result = response.data.result;
  return {
    id: result.id,
    orderNum: result.order_num,
    status: result.status,
    member: result.member_id_info
      ? {
          email: result.member_id_info.email,
        }
      : null,
    paymentMethod: result.payment_method,
    orderItems: result.order_items,
    remark: result.remark,
    totalPrice: result.total_price,
    createdAt: result.created_at,
    createdBy: result.member_id_info.email || "",
    updatedAt: result.updated_at,
    updatedBy: result.updated_by_info ? result.updated_by_info.username : "",
  };
};

const update = async (id, input) => {
  const data = {
    remark: input.remark,
  };

  const response = await axios.post(`order/update/${id}`, data);
  return response.data.success;
};

const deleteOrder = async id => {
  const response = await axios.post(`order/delete/${id}`);
  return response.data.success;
};

const checkout = async (input, type, slotType = "collection") => {
  let refItem = input.refItem;

  if(type === "slot") {
    const inGameGoods = await inGameGoodsApi.list(
      -1,
      10,
      [
        {
          field: "type",
          value: "slot",
          op: "eq",
        },
        {
          field: "slot_type",
          value: slotType,
          op: "eq",
        },
      ],
      "updated_at.DESC",
    );

    if(!inGameGoods) {
      return false;
    }else{
      refItem = inGameGoods.data[0].id;
    }
  }

  const data = {
    member_id: input.memberId.id,
    ref_item: input.refItem,
    state: input.state ? input.state.value : undefined,
    orderDetail: JSON.stringify({
      order_items: [
        {
          id: refItem,
          type: type,
          qty: 1,
          remark: "",
          cover_image: input.customCoverImage || undefined,
          media_src: input.mediaSrc || undefined,
          custom_title: input.customTitle || undefined,
          custom_desc: input.customDesc || undefined,
        },
      ],
      remark: "",
    }),
  };
  
  const response = await axios.post(`order/checkout`, data);

  return response.data;
};

export const OrderApi = {
  list,
  details,
  update,
  deleteOrder,
  checkout,
};
