import AuthProvider from "../components/AuthProvider";
import DashboardLayout from "../components/DashboardLayout";
import { getAppSettingPageName, getSettingsPageName } from "../helper/GlobalValueHelper";
import List from "../views/settings/List";
// import AppSettingCreate from "../views/settings/app/Create";
// import AppSettingEdit from "../views/settings/app/Edit";
// import AppSettingList from "../views/settings/app/List";

const SettingsRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/settings",
      element: (
        <AuthProvider page={getSettingsPageName()} action={"view"}>
          <List />
        </AuthProvider>
      ),
    },
    // {
    //   path: "/settings/app-setting",
    //   element: (
    //     <AuthProvider page={getAppSettingPageName()} action={"view"}>
    //       <AppSettingList />
    //     </AuthProvider>
    //   ),
    // },
    // {
    //   path: "/settings/app-setting/create",
    //   element: (
    //     <AuthProvider page={getAppSettingPageName()} action={"create"}>
    //       <AppSettingCreate />
    //     </AuthProvider>
    //   ),
    // },
    // {
    //   path: "/settings/app-setting/:id",
    //   element: (
    //     <AuthProvider page={getAppSettingPageName()} action={"update"}>
    //       <AppSettingEdit />
    //     </AuthProvider>
    //   ),
    // },
  ],
};

export default SettingsRoute;
