import { Breadcrumbs, Container, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

// components
import ActionFooter from "../../components/ActionFooter";
import FullScreenLoading from "../../components/FullScreenLoading";
import RoleBaseInfo from "../../components/role/RoleBaseInfo";

// api
import { PermissionApi } from "../../api/PermissionApi";
import { PermissionModuleApi } from "../../api/PermissionModuleApi";
import { RoleApi } from "../../api/RoleApi";

// validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required(
    <Translation>{t => t("error.name_is_required")}</Translation>,
  ),
});

export default function RoleEdit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const [permissionModule, setPermissionModule] = useState([]);

  const formik = useFormik({
    initialValues: {
      name: "",
      type: "user",
      permission: {},
    },
    validateOnMount: true,
    validationSchema: validationSchema,
  });

  useEffect(() => {
    if (!id) {
      navigate(-1);
      return;
    }

    fetchData();
    fetchPermissionModuleData();
  }, []);

  const fetchPermissionModuleData = async () => {
    try {
      setFullScreenLoading(true);
      const response = await PermissionModuleApi.list(1, -1, []);
      setPermissionModule(response.data);
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const getPermission = () => {
    let permission = {};
    permissionModule.forEach(item => {
      permission[item.module] = item.actions;
    });
    return permission;
  };

  const fetchData = async () => {
    try {
      setFullScreenLoading(true);
      const result = await RoleApi.details(id);
      formik.setValues(result);

      let permission = getPermission();
      let permissionResult = await PermissionApi.permissionDetails(id);
      for (const [key, value] of Object.entries(permissionResult)) {
        permission[key] = value;
        if (value.length === 0) {
          permission[key] = [];
        }
      }

      formik.setFieldValue("permission", permission);

      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const update = async () => {
    try {
      formik.setTouched({
        name: true,
      });

      formik.validateForm();

      if (!formik.isValid) {
        return;
      }

      const user = {
        ...formik.values,
      };

      setFullScreenLoading(true);

      const success = await RoleApi.update(id, user);
      if (success) {
        const saveSuccess = await PermissionApi.save(id, user.permission);

        if (saveSuccess) {
          toast.success(t("layout.updated_successfully"));
        } else {
          toast.error(t("layout.update_failed"));
        }
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  const onDelete = async () => {
    try {
      setFullScreenLoading(true);
      const success = await RoleApi.deleteRole(id);
      if (success) {
        toast.success(t("layout.deleted_successfully"));
        navigate("/role");
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/settings"}>{t("menu.settings")}</Link>
        <Link to={"/role"}>{t("menu.role")}</Link>
        <Typography color="text.primary">{t("layout.edit")}</Typography>
      </Breadcrumbs>
      <RoleBaseInfo formik={formik} onDelete={onDelete} permissionConfig={getPermission()} />
      <ActionFooter onSubmit={update} onCancel={onCancel} />
    </Container>
  );
}
