import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Autocomplete,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import ReactSwal from "../../helper/AlertHelper";
import { filterTypeList, statusList } from "../../helper/GlobalValueHelper";
import DebugLogger from "../DebugLogger";
import UpdaterInfo from "../UpdaterInfo";

export default function FilterInfo({ data, setData, deleteFilter, haveDeletePermission }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showDeleteConfirm = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        deleteFilter();
      }
    });
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={data.id ? t("filter.edit_filter") : t("filter.new_filter")}
        action={
          deleteFilter && haveDeletePermission ? (
            <Box>
              <IconButton onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={showDeleteConfirm}>
                  <Translation>
                    {t => <Typography color={"error"}>{t("layout.delete")}</Typography>}
                  </Translation>
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <Box />
          )
        }
      />

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DebugLogger title={"Filter"} data={data} hidden={true} />
          </Grid>

          <Grid item xs={12} md={6}>
            <Autocomplete
              options={filterTypeList}
              getOptionLabel={option => {
                return t(`menu.${option.name}`);
              }}
              value={data.type}
              filterSelectedOptions
              isOptionEqualToValue={(opt, value) => {
                return opt.key === value.key;
              }}
              onChange={(event, newValue) => {
                setData({ ...data, type: newValue });
              }}
              renderInput={params => <TextField required {...params} label={t("filter.type")} />}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Autocomplete
              options={statusList}
              getOptionLabel={option => {
                return t(`filter.${option.name}`);
              }}
              value={data.state}
              filterSelectedOptions
              isOptionEqualToValue={(opt, value) => {
                return opt.key === value.key;
              }}
              onChange={(event, newValue) => {
                setData({ ...data, state: newValue });
              }}
              renderInput={params => <TextField {...params} label={t("layout.state")} />}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              value={data.subType}
              onChange={event => setData({ ...data, subType: event.target.value })}
              required
              fullWidth
              autoFocus
              label={t("filter.sub_type")}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              value={data.tag}
              onChange={event => setData({ ...data, tag: event.target.value })}
              required
              fullWidth
              autoFocus
              label={t("filter.tag")}
            />
          </Grid>

          <UpdaterInfo data={data} />
        </Grid>
      </CardContent>

      <CardActions sx={{ justifyContent: "space-between", px: 2 }}></CardActions>
    </Card>
  );
}
