import {
  Box,
  CardActions,
  CardHeader,
  Checkbox,
  Grid,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

// styles
import { getPlaceHolderImage } from "../../helper/UnitsHelper";
import palette from "../../styles/theme/palette";

export default function MemberScenesTransform({
  header,
  list,
  data,
  handleCheckboxChange,
  total,
  page,
  pageSize,
  onChangePage,
  setData,
  setNull,
  type,
}) {
  const { t } = useTranslation();

  const getImage = image => {
    if(image?.customCoverImage && image?.customCoverImage.length > 0){
      return image.customCoverImage;
    }

    if(image?.refItem?.cover_image && image?.refItem?.cover_image.length > 0){
      return image?.refItem?.cover_image;
    }

    return getPlaceHolderImage();
  }

  return (
    <Box>
      <CardHeader title={header} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 2,
        }}
      >
        <Box
          component="label"
          sx={{
            position: "relative",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            p: 2,
            mb: 2,
            borderRadius: 1,
            border: data?.id == null ? `1px solid ${palette.success.main}` : `1px solid #e0e0e0`,
          }}
        >
          <Box
            sx={{
              position: "relative",
              display: "flex",
            }}
          >
            <Box
              component="img"
              src={getPlaceHolderImage()}
              sx={{
                objectFit: "contain",
                maxWidth: "100%",
                maxHeight: "100%",
              }}
              onLoad={e => {
                const img = e.target;
                const { naturalWidth, naturalHeight } = img;
                const imgAspectRatio = naturalWidth / naturalHeight;

                let width, height;

                if (imgAspectRatio > 1) {
                  width = 300 * imgAspectRatio;
                  height = 300;
                } else {
                  width = 300;
                  height = 300 / imgAspectRatio;
                }

                img.style.width = width + "px";
                img.style.height = height + "px";
              }}
            />
          </Box>
          <Stack direction={"row"} justifyContent={"center"}>
            <Checkbox checked={data?.id == null} onChange={e => setNull(type)} />
          </Stack>
        </Box>
        {list &&
          list.length > 0 &&
          list.map((image, index) => (
            <Box
              key={index}
              component="label"
              sx={{
                position: "relative",
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                p: 2,
                mb: 2,
                borderRadius: 1,
                border:
                  data?.id == image.id ? `1px solid ${palette.success.main}` : `1px solid #e0e0e0`,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                }}
              >
                <Box
                  component="img"
                  src={getImage(image)}
                  sx={{
                    objectFit: "contain",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                  onLoad={e => {
                    const img = e.target;
                    const { naturalWidth, naturalHeight } = img;
                    const imgAspectRatio = naturalWidth / naturalHeight;

                    let width, height;

                    if (imgAspectRatio > 1) {
                      width = 300 * imgAspectRatio;
                      height = 300;
                    } else {
                      width = 300;
                      height = 300 / imgAspectRatio;
                    }

                    img.style.width = width + "px";
                    img.style.height = height + "px";
                  }}
                />
              </Box>
              <Stack direction={"row"} justifyContent={"center"} mt={1}>
                <Typography variant="g7">
                  {image?.customTitle || image?.refItem?.title || ""}
                </Typography>
              </Stack>
              <Stack direction={"row"} justifyContent={"center"}>
                <Checkbox
                  checked={data?.id == image.id}
                  onChange={e => handleCheckboxChange(image)}
                />
              </Stack>
            </Box>
          ))}
      </Box>

      <CardActions sx={{ justifyContent: "space-between", px: 2 }}>
        <Typography variant="body2">
          {t("layout.total")}: {total}
        </Typography>
        <Stack spacing={2}>
          <Pagination
            page={page}
            count={Math.ceil(total / pageSize)}
            shape="rounded"
            onChange={(event, value) => {
              onChangePage(value);
            }}
          />
        </Stack>
      </CardActions>

      {data?.id && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label={t("layout.x")}
              type="number"
              value={data?.transform.x || 0}
              onChange={event => {
                setData("x", parseInt(event.target.value));
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label={t("layout.y")}
              type="number"
              value={data?.transform.y || 0}
              onChange={event => {
                setData("y", parseInt(event.target.value));
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label={t("layout.z")}
              type="number"
              value={data?.transform.z || 0}
              onChange={event => {
                setData("z", parseInt(event.target.value));
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label={t("layout.x_rotate")}
              type="number"
              value={data?.transform.x_rotate || 0}
              onChange={event => {
                setData("x_rotate", parseInt(event.target.value));
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label={t("layout.y_rotate")}
              type="number"
              value={data?.transform.y_rotate || 0}
              onChange={event => {
                setData("y_rotate", parseInt(event.target.value));
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label={t("layout.z_rotate")}
              type="number"
              value={data?.transform.z_rotate || 0}
              onChange={event => {
                setData("z_rotate", parseInt(event.target.value));
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t("layout.scale")}
              type="number"
              value={data?.transform.scale || 0}
              onChange={event => {
                setData("scale", parseInt(event.target.value));
              }}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
