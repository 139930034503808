import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

// helper
import ReactSwal from "../../helper/AlertHelper";
import { langList, maxUploadFileMb, roleList, stateList } from "../../helper/GlobalValueHelper";
import { resizeFile } from "../../helper/UnitsHelper";

// icons
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MinusIcon from "@mui/icons-material/Remove";

// components
import DebugLogger from "../../components/DebugLogger";
import FullScreenLoading from "../FullScreenLoading";
import UpdaterInfo from "../UpdaterInfo";
import ValidationTextField from "../ValidationTextField";

// api
import { MemberApi } from "../../api/MemberApi";

export default function MemberBaseInfo({
  formik,
  onDelete,
  onReset,
  haveDeletePermission,
  isCreate = false,
  fetchData,
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [memberPointsData, setMemberPointsData] = useState({
    memberID: formik.values.id,
    event: "",
    qtyChange: 0,
    remark: "",
  });
  const [attributeList, setAttributeList] = useState([]);
  const open = Boolean(anchorEl);
  const [images, setImages] = useState([]);
  const [deleteUploadFiles, setDeleteUploadFiles] = useState([]);
  const [deleteCurrentUploadFiles, setDeleteCurrentUploadFiles] = useState([]);
  const [showImageUploader, setShowImageUploader] = useState(false);
  const [fullScreenLoading, setFullScreenLoading] = useState(false);

  useEffect(() => {
    let newImages = [];

    if (formik.values.photo) {
      newImages = [...images, formik.values.photo];
    }

    if (formik.values.uploadFiles) {
      formik.values.uploadFiles.forEach(file => {
        newImages.push(URL.createObjectURL(file));
      });
    }

    setImages(newImages);
  }, [JSON.stringify(formik.values)]);

  useEffect(() => {
    // console.log("formik.values", formik.values.photo, showImageUploader);
    if (!formik.values.photo) {
      setShowImageUploader(true);
      return;
    }
    let imageLength = 1;
    imageLength += formik.values.uploadFiles?.length || 0;

    let variantLength = 1;

    if (imageLength >= variantLength) {
      setShowImageUploader(false);
    } else {
      setShowImageUploader(true);
    }
  }, [JSON.stringify(formik.values)]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetMemberPointsData = () => {
    setMemberPointsData({
      memberID: formik.values.id,
      event: "",
      qtyChange: 0,
      remark: "",
    });
  };

  const handleClickOpen = type => {
    setOpenAddDialog(true);
  };

  const handleClickClose = () => {
    setOpenAddDialog(false);
    resetMemberPointsData();
  };

  const showDeleteConfirm = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        onDelete();
      }
    });
  };

  const showResetConfirm = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.reset_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        onReset();
      }
    });
  };

  const validateForm = () => {
    let hasError = false;

    if (!memberPointsData.qtyChange || memberPointsData.qtyChange <= 0) {
      toast.error(t("error.number_must_be_greater_than_zero"));
      hasError = true;
    }

    if (!memberPointsData.remark) {
      toast.error(t("error.remark_required"));
      hasError = true;
    }

    return hasError;
  };

  const onChangeMemberPoints = async () => {
    let error = validateForm();

    if (error) {
      return;
    }

    const data = { ...memberPointsData };

    handleClickClose();

    try {
      setFullScreenLoading(true);
      const success = await MemberApi.changeMemberPoints(data);
      if (success) {
        toast.success(t("layout.updated_successfully"));
        fetchData();
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onImageChange = async images => {
    let uploadFiles = formik.values.uploadFiles || [];

    // check if file exists
    let fileExists = false;
    uploadFiles.forEach(file => {
      images.forEach(image => {
        if (file.name === image.file.name) {
          fileExists = true;
          return;
        }
      });
    });

    if (fileExists) {
      return;
    }

    if (images && images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        let extension = image.file.name.split(".").pop().toUpperCase();
        if (extension === "JPG") extension = "JPEG";
        if (image.file.size > 1024 * 1024 * maxUploadFileMb) {
          toast.error(`Image ${i + 1} file size must be less than ${maxUploadFileMb}MB`);
          return;
        }

        const resizedImage = await resizeFile({
          file: image.file,
          extension: extension,
        });

        uploadFiles.push(resizedImage);
      }
    }

    formik.setFieldValue("uploadFiles", uploadFiles);
    images = null;
  };

  const onRemoveImage = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        formik.setFieldValue("photo", "");
        formik.setFieldValue("uploadFiles", []);
        setImages([]);
      }
    });
  };

  const removeUploadFile = index => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        let newUploadFiles = formik.values.uploadFiles;
        newUploadFiles.splice(index, 1);
        formik.setFieldValue("uploadFiles", newUploadFiles);
      }
    });
  };

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 5,
      }}
    >
      <FullScreenLoading open={fullScreenLoading} />
      <CardHeader
        title={t("layout.member_general")}
        subheader={<Typography variant="caption">{t("member.manage_member_profile")}</Typography>}
        action={
          onDelete &&
          haveDeletePermission && (
            <Box>
              <IconButton onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={showResetConfirm}>
                  <Typography color={"error"}>{t("layout.reset")}</Typography>
                </MenuItem>
                <MenuItem onClick={showDeleteConfirm}>
                  <Typography color={"error"}>{t("layout.delete")}</Typography>
                </MenuItem>
              </Menu>
            </Box>
          )
        }
      />

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DebugLogger title="Members" data={formik.values} hidden={true}></DebugLogger>
            <DebugLogger title="Member Points" data={memberPointsData} hidden={true}></DebugLogger>
          </Grid>

          {/* <Grid item xs={12}>
            <Typography
              sx={{
                pb: 2,
              }}
            >
              {t("member.profile_photo")}
            </Typography>

            <Box
              sx={{
                mb: 2,
                fontWight: "bold",
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography variant="caption">
                {t("page.maximum_upload_size")}: {maxUploadFileMb} MB
              </Typography>
              <Typography variant="caption">
                {t("page.accept_file_type")}: JPEG / JPG / PNG / WEBP / SVG
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            {formik.values.photo && (
              <Grid item xs={12}>
                <Stack>
                  <Box
                    component={"img"}
                    src={formik.values.photo}
                    sx={{
                      objectFit: "contain",
                      maxWidth: "100%",
                      maxHeight: "200px",
                    }}
                  />
                  <Button
                    sx={{
                      p: 2,
                    }}
                    onClick={() => {
                      onRemoveImage();
                    }}
                  >
                    {t("layout.remove")}
                  </Button>
                </Stack>
              </Grid>
            )}

            {formik.values.uploadFiles &&
              formik.values.uploadFiles.length > 0 &&
              formik.values.uploadFiles.map((file, index) => (
                <Grid item xs={12} key={index}>
                  <Stack>
                    <Box
                      component={"img"}
                      src={URL.createObjectURL(file)}
                      sx={{
                        objectFit: "contain",
                        maxWidth: "100%",
                        maxHeight: "200px",
                      }}
                    />
                    <Button
                      sx={{
                        p: 2,
                      }}
                      onClick={() => {
                        removeUploadFile(index);
                      }}
                    >
                      {t("layout.remove")}
                    </Button>
                  </Stack>
                </Grid>
              ))}
            {showImageUploader && (
              <ImageUploader
                maxNumber={1}
                images={images}
                maxFileSize={1024 * 1024 * maxUploadFileMb}
                onImageChange={onImageChange}
                onRemoveImage={onRemoveImage}
                removeAllButton={false}
              />
            )}
          </Grid> */}

          <Grid item xs={12} md={6}>
            <ValidationTextField
              name={"email"}
              type={"text"}
              required
              value={formik.values.email || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title={t("user.email")}
              errorText={formik.errors.email}
              touched={formik.touched.email}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <ValidationTextField
              name={"password"}
              type={"password"}
              required={isCreate}
              value={formik.values.password || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title={t("user.password")}
              errorText={formik.errors.password}
              touched={formik.touched.password}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Autocomplete
              options={langList}
              getOptionLabel={option => {
                return t(`layout.${option.name}`);
              }}
              value={formik.values.lang}
              filterSelectedOptions
              isOptionEqualToValue={(opt, value) => {
                return opt.key === value.key;
              }}
              onChange={(event, newValue) => {
                formik.setFieldValue("lang", newValue);
              }}
              renderInput={params => <TextField {...params} label={t("layout.langauge")} />}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Autocomplete
              options={roleList}
              getOptionLabel={option => {
                return t(`member.${option.name}`);
              }}
              value={formik.values.role}
              filterSelectedOptions
              isOptionEqualToValue={(opt, value) => {
                return opt.key === value.key;
              }}
              onChange={(event, newValue) => {
                formik.setFieldValue("role", newValue);
              }}
              renderInput={params => <TextField {...params} label={t("layout.role")} />}
            />
          </Grid>

          <Grid item xs={6}>
            <Stack direction={"row"} height={"100%"} spacing={2}>
              <Box flex={1}>
                <ValidationTextField
                  name={"points"}
                  type={"number"}
                  value={formik.values.points}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  title={t("member.points")}
                  errorText={formik.errors.points}
                  touched={formik.touched.points}
                  disabled={!isCreate}
                />
              </Box>
              {!isCreate && (
                <Stack
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  spacing={2}
                  height={"100%"}
                >
                  <Button
                    sx={{
                      height: "100%",
                      borderColor: "grey.400",
                    }}
                    fullWidth
                    color="inherit"
                    variant="outlined"
                    onClick={() => {
                      handleClickOpen();
                      setMemberPointsData({ ...memberPointsData, event: "minus" });
                    }}
                  >
                    <MinusIcon />
                  </Button>
                  <Button
                    sx={{
                      height: "100%",
                      borderColor: "grey.400",
                    }}
                    fullWidth
                    color="inherit"
                    variant="outlined"
                    onClick={() => {
                      handleClickOpen();
                      setMemberPointsData({ ...memberPointsData, event: "add" });
                    }}
                  >
                    <AddIcon />
                  </Button>
                </Stack>
              )}
            </Stack>
          </Grid>

          <Dialog open={openAddDialog} onClose={handleClickClose} scroll={"body"}>
            <Card variant="outlined" sx={{ width: 400 }}>
              <CardHeader
                title={
                  memberPointsData.event == "minus"
                    ? t("member.points_minus")
                    : t("member.points_add")
                }
              />

              <CardContent>
                <Grid item xs={12}>
                  <TextField
                    name={"qtyChange"}
                    type={"number"}
                    value={memberPointsData.qtyChange}
                    onChange={e =>
                      setMemberPointsData({ ...memberPointsData, qtyChange: e.target.value })
                    }
                    fullWidth
                    required
                    label={t("member.points")}
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                    sx={{ mb: 2 }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name={"remark"}
                    type={"text"}
                    required
                    value={memberPointsData.remark}
                    onChange={e =>
                      setMemberPointsData({ ...memberPointsData, remark: e.target.value })
                    }
                    fullWidth
                    label={t("layout.remark")}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"end"}
                  alignItems={"center"}
                  pt={2}
                >
                  <Button onClick={handleClickClose}>{t("layout.cancel")}</Button>
                  <Button
                    onClick={() => {
                      onChangeMemberPoints();
                    }}
                  >
                    {t("layout.ok")}
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Dialog>

          <Grid item xs={12} md={6}>
            <Autocomplete
              options={stateList}
              getOptionLabel={option => {
                return t(`member.${option.name}`);
              }}
              value={formik.values.state}
              filterSelectedOptions
              isOptionEqualToValue={(opt, value) => {
                return opt.key === value.key;
              }}
              onChange={(event, newValue) => {
                formik.setFieldValue("state", newValue);
              }}
              renderInput={params => <TextField {...params} label={t("layout.state")} />}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              mt: 2,
            }}
          >
            <UpdaterInfo data={formik.values} />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: "space-between", px: 2 }}></CardActions>
    </Card>
  );
}
