import { Box, Breadcrumbs, Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

// helper
import { checkPermission } from "../../helper/PermissionHelper";
import { pageName } from "../../reducers/commonSlice";
import { user } from "../../reducers/userSlice";

// components
import ActionFooter from "../../components/ActionFooter";
import FullScreenLoading from "../../components/FullScreenLoading";
import OrderBaseInfo from "../../components/order/OrderBaseInfo";

// api
import { OrderApi } from "../../api/OrderApi";

export default function OrderDetail() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [order, setOrder] = useState({
    id: "",
    orderNum: "",
    status: "",
    member: {},
    paymentMethod: "",
    orderItems: [],
    remark: "",
  });
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const [haveDeletePermission, setHaveDeletePermission] = useState(false);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);

  useEffect(() => {
    if (!id) {
      navigate(-1);
    }
    fetchData();
  }, []);

  useEffect(() => {
    const haveDeletePermission = checkPermission(currentUser, currentPage, "delete");

    setHaveDeletePermission(haveDeletePermission);
  }, [currentUser, currentPage]);

  const fetchData = async () => {
    try {
      setFullScreenLoading(true);
      const response = await OrderApi.details(id);
      setOrder(response);
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  const update = async () => {
    try {
      setFullScreenLoading(true);

      const success = await OrderApi.update(id, order);
      if (success) {
        toast.success(t("layout.updated_successfully"));
        fetchData();
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const deleteOrder = async () => {
    try {
      setFullScreenLoading(true);
      const success = await OrderApi.deleteOrder(id);

      if (success) {
        toast.success(t("order.delete_successfully"));
        navigate(-1);
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/orders"}>{t("menu.orders")}</Link>
        <Typography color="text.primary">{order.orderNum}</Typography>
      </Breadcrumbs>
      <Box pb={8}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OrderBaseInfo
              order={order}
              setOrder={setOrder}
              haveDeletePermission={haveDeletePermission}
              deleteOrder={deleteOrder}
            />
          </Grid>
        </Grid>
      </Box>
      <ActionFooter onCancel={onCancel} onSubmit={update} />
    </Container>
  );
}
