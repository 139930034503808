import Palette from "../palette.js";

const Element = {
  styleOverrides: {
    root: {
      boxShadow: "none",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      backgroundColor: Palette.background.paper,
      color: Palette.text.primary,
    },
  },
};

export default Element;
