import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Autocomplete,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import ReactSwal from "../../helper/AlertHelper";
import { statusList } from "../../helper/GlobalValueHelper";
import { getPlaceHolderImage } from "../../helper/UnitsHelper";
import DebugLogger from "../DebugLogger";
import UpdaterInfo from "../UpdaterInfo";
// styles
import palette from "../../styles/theme/palette";

export default function MemberEffectsInfo({
  effects,
  memberEffect,
  setMemberEffect,
  deleteMemberEffects,
  haveDeletePermission,
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showDeleteConfirm = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        deleteMemberEffects();
      }
    });
  };

  const handleCheckboxChange = image => {
    setMemberEffect({
      ...memberEffect,
      refItem: image.id,
    });
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={memberEffect.id ? t("member.edit_member_effects") : t("member.new_member_effects")}
        action={
          deleteMemberEffects && haveDeletePermission ? (
            <Box>
              <IconButton onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={showDeleteConfirm}>
                  <Translation>
                    {t => <Typography color={"error"}>{t("layout.delete")}</Typography>}
                  </Translation>
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <Box />
          )
        }
      />

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DebugLogger title={"Member Effect"} data={memberEffect} hidden={true} />
            <DebugLogger title={"effects"} data={effects} hidden={true} />
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: 2,
              }}
            >
              {effects &&
                effects.length > 0 &&
                effects.map((image, index) => (
                  <Box
                    key={index}
                    component="label"
                    sx={{
                      position: "relative",
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      p: 2,
                      borderRadius: 1,
                      border:
                        memberEffect.refItem == image.id
                          ? `1px solid ${palette.success.main}`
                          : `1px solid #e0e0e0`,
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        display: "flex",
                      }}
                    >
                      <Box
                        component="img"
                        src={
                          image.coverImage.length === 0 ? getPlaceHolderImage() : image.coverImage
                        }
                        sx={{
                          objectFit: "contain",
                          maxWidth: "100%",
                          maxHeight: "100%",
                        }}
                        onLoad={e => {
                          const img = e.target;
                          const { naturalWidth, naturalHeight } = img;
                          const imgAspectRatio = naturalWidth / naturalHeight;

                          let width, height;

                          if (imgAspectRatio > 1) {
                            width = 300 * imgAspectRatio;
                            height = 300;
                          } else {
                            width = 300;
                            height = 300 / imgAspectRatio;
                          }

                          img.style.width = width + "px";
                          img.style.height = height + "px";
                        }}
                      />
                    </Box>
                    <Box sx={{ textAlign: "center", mt: 1 }}>
                      <Typography>{image.title}</Typography>
                    </Box>
                    <Stack direction={"row"} justifyContent={"center"}>
                      <Checkbox
                        checked={memberEffect.refItem == image.id}
                        onChange={e => handleCheckboxChange(image)}
                      />
                    </Stack>
                  </Box>
                ))}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              options={statusList}
              getOptionLabel={option => {
                return t(`member.effects_${option.name}`);
              }}
              value={memberEffect.state}
              filterSelectedOptions
              isOptionEqualToValue={(opt, value) => {
                return opt.key === value.key;
              }}
              onChange={(event, newValue) => {
                setMemberEffect({ ...memberEffect, state: newValue });
              }}
              renderInput={params => <TextField {...params} label={t("layout.state")} />}
            />
          </Grid>

          <UpdaterInfo data={memberEffect} />
        </Grid>
      </CardContent>

      <CardActions sx={{ justifyContent: "space-between", px: 2 }}></CardActions>
    </Card>
  );
}
