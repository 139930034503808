import { Box, CardActions, Checkbox, Pagination, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getPlaceHolderImage } from "../../helper/UnitsHelper";
import palette from "../../styles/theme/palette";

export default function RedeemCodeGiftsList({
  header,
  data,
  setData,
  list,
  total,
  page,
  pageSize,
  setNull,
  onChangePage,
}) {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="h5">{header}</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 2,
          mt: 2,
        }}
      >
        <Box
          component="label"
          sx={{
            position: "relative",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            p: 2,
            mb: 2,
            borderRadius: 1,
            border: data.length === 0 ? `1px solid ${palette.success.main}` : `1px solid #e0e0e0`,
          }}
        >
          <Box
            sx={{
              position: "relative",
              display: "flex",
            }}
          >
            <Box
              component="img"
              src={getPlaceHolderImage()}
              sx={{
                objectFit: "contain",
                maxWidth: "100%",
                maxHeight: "100%",
              }}
              onLoad={e => {
                const img = e.target;
                const { naturalWidth, naturalHeight } = img;
                const imgAspectRatio = naturalWidth / naturalHeight;

                let width, height;

                if (imgAspectRatio > 1) {
                  width = 300 * imgAspectRatio;
                  height = 300;
                } else {
                  width = 300;
                  height = 300 / imgAspectRatio;
                }

                img.style.width = width + "px";
                img.style.height = height + "px";
              }}
            />
          </Box>
          <Stack direction={"row"} justifyContent={"center"}>
            <Checkbox checked={data.length == 0} onChange={setNull} />
          </Stack>
        </Box>
        {list &&
          list.length > 0 &&
          list.map((item, index) => (
            <Box
              key={index}
              component="label"
              sx={{
                position: "relative",
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                p: 2,
                mb: 2,
                borderRadius: 1,
                border: data.includes(item.id)
                  ? `1px solid ${palette.success.main}`
                  : `1px solid #e0e0e0`,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                }}
              >
                <Box
                  component="img"
                  src={item.coverImage || getPlaceHolderImage()}
                  sx={{
                    objectFit: "contain",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                  onLoad={e => {
                    const img = e.target;
                    const { naturalWidth, naturalHeight } = img;
                    const imgAspectRatio = naturalWidth / naturalHeight;

                    let width, height;

                    if (imgAspectRatio > 1) {
                      width = 300 * imgAspectRatio;
                      height = 300;
                    } else {
                      width = 300;
                      height = 300 / imgAspectRatio;
                    }

                    img.style.width = width + "px";
                    img.style.height = height + "px";
                  }}
                />
              </Box>
              <Stack direction={"row"} justifyContent={"center"} mt={1}>
                <Typography variant="g7">{item.title}</Typography>
              </Stack>
              <Stack direction={"row"} justifyContent={"center"}>
                <Checkbox checked={data.includes(item.id)} onChange={e => setData(e, item)} />
              </Stack>
            </Box>
          ))}
      </Box>

      <CardActions sx={{ justifyContent: "space-between", px: 2 }}>
        <Typography variant="body2">
          {t("layout.total")}: {total}
        </Typography>
        <Stack spacing={2}>
          <Pagination
            page={page}
            count={Math.ceil(total / pageSize)}
            shape="rounded"
            onChange={(event, value) => {
              onChangePage(value);
            }}
          />
        </Stack>
      </CardActions>
    </Box>
  );
}
