import { Box, Breadcrumbs, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

// components
import ActionFooter from "../../../components/ActionFooter";
import FullScreenLoading from "../../../components/FullScreenLoading";
import MemberRedeemedInfo from "../../../components/member/MemberRedeemedInfo";

// api
import { MemberApi } from "../../../api/MemberApi";
import { RedeemCodeApi } from "../../../api/RedeemCodeApi";

export default function MemberRedeemedCodeCreate() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({
    memberId: null,
    redeemCodeId: null,
    state: {
      key: 1,
      name: "active",
      value: "active",
    },
  });
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const [page, setPage] = useState(-1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState();
  const [members, setMembers] = useState([]);
  const [redeemCodes, setRedeemCodes] = useState([]);

  useEffect(() => {
    fetchMemberData();
    fetchRedeemCodeData();
  }, [page]);

  const fetchMemberData = async () => {
    try {
      setFullScreenLoading(true);
      const response = await MemberApi.details(id);
      setFullScreenLoading(false);
      setMembers([response]);
      setData({ ...data, memberId: response });
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const fetchRedeemCodeData = async () => {
    try {
      setFullScreenLoading(true);
      const response = await RedeemCodeApi.list(page, pageSize, filter);
      setFullScreenLoading(false);
      setRedeemCodes(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const validateForm = () => {
    let error = false;

    if (!data.memberId) {
      toast.error(t("error.member_is_required"));
      error = true;
    }

    if (!data.redeemCodeId) {
      toast.error(t("error.redeem_code_is_required"));
      error = true;
    }

    return error;
  };

  const onSubmit = async () => {
    try {
      let error = validateForm();

      if (error) {
        return;
      }

      setFullScreenLoading(true);
      const success = await MemberApi.memberRedeemedCreate(data);
      if (success) {
        toast.success(t("layout.created_successfully"));
        navigate(`/members/${id}`);
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/members"}>{t("menu.account")}</Link>
        <Link to={`/members/${id}`}>{t("menu.members")}</Link>
        <Typography color="text.primary">{t("layout.create")}</Typography>
      </Breadcrumbs>
      <Box component="form">
        <MemberRedeemedInfo
          data={data}
          setData={setData}
          members={members}
          redeemCodes={redeemCodes}
        />
        <Box sx={{ my: 8 }} />
        <ActionFooter onSubmit={onSubmit} onCancel={onCancel} />
      </Box>
    </Container>
  );
}
