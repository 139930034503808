import axios from "../helper/AxiosHelper";

const save = async (roleId, permission) => {
  const data = {
    role_id: roleId,
    permission: permission,
  };

  const response = await axios.post(`permission/save`, data);

  return response.data.success;
};

const permissionDetails = async id => {
  const response = await axios.get(`permission/permissionDetails/${id}`);
  const result = response.data.result;

  return result;
};

export const PermissionApi = {
  save,
  permissionDetails,
};
