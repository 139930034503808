import { Box, Button, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ReactSwal from "../helper/AlertHelper";
import { checkPermission } from "../helper/PermissionHelper";
import { actionName, pageName } from "../reducers/commonSlice";
import { user } from "../reducers/userSlice";

export default function ActionFooter({
  onSubmit,
  onCancel,
  cancelButton = true,
  submitButton = true,
  disableSaveBtn,
}) {
  const [haveCreatePermission, setHaveCreatePermission] = useState(false);
  const [haveEditPermission, setHaveEditPermission] = useState(false);
  const currentPage = useSelector(pageName);
  const currentAction = useSelector(actionName);
  const currentUser = useSelector(user);
  const { t } = useTranslation();

  useEffect(() => {
    const createPermission = checkPermission(currentUser, currentPage, "create");
    setHaveCreatePermission(createPermission);

    const editPermission = checkPermission(currentUser, currentPage, "update");
    setHaveEditPermission(editPermission);
  }, [currentUser, currentPage, currentAction]);

  const handleOnCancel = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.cancel_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        onCancel();
      }
    });
  };

  return (
    <Box
      component={Paper}
      sx={{
        position: "fixed",
        right: 0,
        left: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "end",
        zIndex: 1,
      }}
    >
      {cancelButton && (
        <Button
          sx={{ py: 1.5, px: 3, m: 1 }}
          size="large"
          variant="outlined"
          onClick={handleOnCancel}
        >
          <Translation>{t => <>{t("layout.cancel")}</>}</Translation>
        </Button>
      )}
      {submitButton && (haveEditPermission || haveCreatePermission) && (
        <Button
          sx={{ py: 1.5, px: 3, m: 1, mr: 3 }}
          disableElevation
          variant="contained"
          onClick={onSubmit}
          disabled={disableSaveBtn}
        >
          <Translation>{t => <>{t("layout.save")}</>}</Translation>
        </Button>
      )}
    </Box>
  );
}
