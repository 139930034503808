import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ReactSwal from "../../helper/AlertHelper";
import { maxUploadFileMb, statusList } from "../../helper/GlobalValueHelper";
import DebugLogger from "../DebugLogger";
import ImageUploader from "../ImageUploader";
import UpdaterInfo from "../UpdaterInfo";
import { resizeFile } from "../../helper/UnitsHelper";

export default function BackdropInfo({
  backdrop,
  setBackdrop,
  deleteBackdrop,
  haveDeletePermission,
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [images, setImages] = useState([]);
  const [showImageUploader, setShowImageUploader] = useState(false);

  useEffect(() => {
    if (!backdrop) return;
    let imageLength = backdrop.coverImage?.length;
    imageLength += backdrop.uploadFiles?.length || 0;

    let variantLength = 1;

    if (imageLength >= variantLength) {
      setShowImageUploader(false);
    } else {
      setShowImageUploader(true);
    }
  }, [backdrop]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showDeleteConfirm = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        deleteBackdrop();
      }
    });
  };

  const onImageChange = async images => {
    let uploadFiles = backdrop.uploadFiles || [];

    // check if file exists
    let fileExists = false;
    uploadFiles.forEach(file => {
      images.forEach(image => {
        if (file.name === image.file.name) {
          fileExists = true;
          return;
        }
      });
    });

    if (fileExists) {
      return;
    }

    if (images && images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        let extension = image.file.name.split(".").pop().toUpperCase();
        if (extension === "JPG") extension = "JPEG";
        if (image.file.size > 1024 * 1024 * maxUploadFileMb) {
          toast.error(`Image ${i + 1} file size must be less than ${maxUploadFileMb}MB`);
          return;
        }

        const resizedImage = await resizeFile({
          file: image.file,
          extension: extension,
        });

        uploadFiles.push(resizedImage);
      }
    }
    setBackdrop({ ...backdrop, uploadFiles: uploadFiles });
    images = null;
  };

  const onRemoveImage = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        setBackdrop({ ...backdrop, coverImage: "" });
      }
    });
  };

  const removeUploadFile = index => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        let newUploadFiles = backdrop.uploadFiles;
        newUploadFiles.splice(index, 1);
        setBackdrop({
          ...backdrop,
          uploadFiles: newUploadFiles,
        });
      }
    });
  };

  const onFileChange = async e => {
    let file = e.target.files[0];
    const fileUrl = URL.createObjectURL(file);
    const size = file.size;

    if (size > 1024 * 1024 * maxUploadFileMb) {
      toast.error(`Asset size must be less than ${maxUploadFileMb}MB`);
      return;
    }

    setBackdrop({ ...backdrop, uploadAssetFiles: [file], mediaSrc: fileUrl });
  };

  const onRemoveAssetFile = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        setBackdrop({
          ...backdrop,
          uploadAssetFiles: undefined,
          mediaSrc: "",
        });
      }
    });
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={backdrop.id ? t("backdrop.edit_backdrop") : t("backdrop.new_backdrop")}
        action={
          deleteBackdrop && haveDeletePermission ? (
            <Box>
              <IconButton onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={showDeleteConfirm}>
                  <Translation>
                    {t => <Typography color={"error"}>{t("layout.delete")}</Typography>}
                  </Translation>
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <Box />
          )
        }
      />

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DebugLogger title={"Backdrop"} data={backdrop} hidden={true} />
          </Grid>

          <Grid item xs={12}>
            <Typography
              sx={{
                pb: 2,
              }}
            >
              {t("layout.cover_image")}
            </Typography>

            <Box
              sx={{
                mb: 2,
                fontWight: "bold",
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography variant="caption">
                {t("page.maximum_upload_size")}: {maxUploadFileMb} MB
              </Typography>
              <Typography variant="caption">
                {t("page.accept_file_type")}: JPEG / JPG / PNG
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              {backdrop.coverImage.length > 0 && (
                <Grid item xs={12}>
                  <Stack>
                    <Box
                      component={"img"}
                      src={backdrop.coverImage}
                      sx={{
                        objectFit: "contain",
                        maxWidth: "100%",
                        maxHeight: "200px",
                      }}
                    />
                    <Button
                      sx={{
                        p: 2,
                      }}
                      onClick={onRemoveImage}
                    >
                      {t("layout.remove")}
                    </Button>
                  </Stack>
                </Grid>
              )}

              {backdrop.uploadFiles &&
                backdrop.uploadFiles.length > 0 &&
                backdrop.uploadFiles.map((file, index) => (
                  <Grid item xs={12} key={index}>
                    <Stack>
                      <Box
                        component={"img"}
                        src={URL.createObjectURL(file)}
                        sx={{
                          objectFit: "contain",
                          maxWidth: "100%",
                          maxHeight: "200px",
                        }}
                      />
                      <Button
                        sx={{
                          p: 2,
                        }}
                        onClick={() => {
                          removeUploadFile(index);
                        }}
                      >
                        {t("layout.remove")}
                      </Button>
                    </Stack>
                  </Grid>
                ))}
            </Grid>
            {showImageUploader && (
              <ImageUploader
                acceptType={["jpg", "jpeg", "png"]}
                maxNumber={1}
                images={images}
                maxFileSize={1024 * 1024 * maxUploadFileMb}
                onImageChange={onImageChange}
                onRemoveImage={onRemoveImage}
                removeAllButton={false}
              />
            )}
          </Grid>

          {/* <Grid item xs={12}>
            <Typography
              sx={{
                pb: 2,
              }}
            >
              {t("backdrop.asset")}
            </Typography>

            <Box
              sx={{
                mb: 2,
                fontWight: "bold",
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography variant="caption">
                {t("page.maximum_upload_size")}: {maxUploadFileMb} MB
              </Typography>
              <Typography variant="caption">{t("page.accept_file_type")}: GLB / GITF</Typography>
            </Box>
          </Grid>

          {backdrop.mediaSrc || backdrop.uploadAssetFiles ? (
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                component={"img"}
                src={"/assets/icons/glb-logo.webp"}
                maxHeight={200}
                maxWidth={"100%"}
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.open(backdrop.mediaSrc, "_blank");
                }}
              />

              <Button
                sx={{
                  p: 2,
                }}
                onClick={onRemoveAssetFile}
              >
                {t("layout.remove")}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Button
                variant="contained"
                component="label"
                fullWidth
                sx={{
                  p: 2,
                  mb: 2,
                }}
              >
                {t("backdrop.click_to_upload_asset_file")}
                <Box
                  component={"input"}
                  type="file"
                  onChange={onFileChange}
                  sx={{
                    display: "none",
                  }}
                  accept=".glb,.gltf"
                />
              </Button>
            </Grid>
          )} */}

          <Grid item xs={12}>
            <TextField
              value={backdrop.assetsId}
              onChange={event => setBackdrop({ ...backdrop, assetsId: event.target.value })}
              required
              fullWidth
              autoFocus
              label={t("backdrop.assets_id")}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              value={backdrop.title}
              onChange={event => setBackdrop({ ...backdrop, title: event.target.value })}
              required
              fullWidth
              autoFocus
              label={t("backdrop.title")}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              type="number"
              value={backdrop.price}
              onChange={event => setBackdrop({ ...backdrop, price: event.target.value })}
              required
              fullWidth
              autoFocus
              label={t("backdrop.price")}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Autocomplete
              options={statusList}
              getOptionLabel={option => {
                return t(`backdrop.${option.name}`);
              }}
              value={backdrop.state}
              filterSelectedOptions
              isOptionEqualToValue={(opt, value) => {
                return opt.key === value.key;
              }}
              onChange={(event, newValue) => {
                setBackdrop({ ...backdrop, state: newValue });
              }}
              renderInput={params => <TextField {...params} label={t("layout.state")} />}
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              multiple
              options={backdrop.tags.map(option => option)}
              freeSolo
              value={backdrop.tags || []}
              onChange={(event, newValue) => {
                setBackdrop({ ...backdrop, tags: newValue });
              }}
              renderTags={(value, getTagProps) =>
                value &&
                value.map((option, index) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} key={option} />
                ))
              }
              filterSelectedOptions
              renderInput={params => (
                <TextField
                  {...params}
                  label={t("effects.tags")}
                  placeholder={t("effects.please_enter_to_confirm_add_tags")}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t("backdrop.description")}
              multiline
              value={backdrop.description}
              onChange={event => {
                setBackdrop({ ...backdrop, description: event.target.value });
              }}
              rows={4}
            />
          </Grid>

          <UpdaterInfo data={backdrop} />
        </Grid>
      </CardContent>

      <CardActions sx={{ justifyContent: "space-between", px: 2 }}></CardActions>
    </Card>
  );
}
