import { Breadcrumbs, Container, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

// components
import ActionFooter from "../../components/ActionFooter";
import FullScreenLoading from "../../components/FullScreenLoading";
import UserBaseInfo from "../../components/user/UserBaseInfo";

// api
import { UserApi } from "../../api/UserApi";

// validation schema
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required(<Translation>{t => t("error.email_is_required")}</Translation>)
    .email(<Translation>{t => t("error.email_invalid_format")}</Translation>),
  username: Yup.string().required(
    <Translation>{t => t("error.username_is_required")}</Translation>,
  ),
  phoneNumber: Yup.string()
    .matches(/^[0-9]*$/, {
      excludeEmptyString: true,
      message: <Translation>{t => t("error.phone_number_invalid_format")}</Translation>,
    })
    .test(
      "minLength",
      <Translation>{t => t("error.phone_number_length_min_8_characters")}</Translation>,
      val => val === undefined || val.length < 1 || val.length > 7,
    ),
  password: Yup.string()
    .required(<Translation>{t => t("error.password_is_required")}</Translation>)
    .min(8, <Translation>{t => t("error.passowrd_length_min_8_characters")}</Translation>)
    .matches(/((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/, {
      excludeEmptyString: false,
      message: <Translation>{t => t("error.password_letter")}</Translation>,
    }),
});

export default function UserCreate() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      username: "",
      nameZh: "",
      nameEn: "",
      email: "",
      phoneNumber: "",
      roles: [],
      remark: "",
      password: "",
    },
    validateOnMount: true,
    validationSchema: validationSchema,
  });
  const [fullScreenLoading, setFullScreenLoading] = useState(false);

  useEffect(() => {}, []);

  const onSubmit = async () => {
    try {
      formik.setTouched({
        email: true,
        username: true,
        password: true,
      });

      formik.validateForm();

      if (!formik.isValid) {
        return;
      }

      let hasError = false;
      if (formik.values.roles.length == 0 || !formik.values.roles) {
        toast.error(t("error.roles_at_least_1_role"));
        hasError = true;
      }

      if (hasError) {
        return;
      }

      if (formik.values.phoneNumber) {
        formik.values.phoneNumber = parseInt(formik.values.phoneNumber);
      }

      const user = {
        ...formik.values,
      };

      setFullScreenLoading(true);

      const id = await UserApi.create(user);
      if (id) {
        toast.success(t("layout.created_successfully"));
        navigate(`/users`);
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/settings"}>{t("menu.settings")}</Link>
        <Link to={"/users"}>{t("menu.users")}</Link>
        <Typography color="text.primary">{t("layout.create")}</Typography>
      </Breadcrumbs>
      <UserBaseInfo formik={formik} isCreate={true} />
      <ActionFooter onSubmit={onSubmit} onCancel={onCancel} />
    </Container>
  );
}
