const formatAmount = amount => {
  amount = parseFloat(amount);
  return "$" + amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

const formatPaymentMethod = paymentMethod => {
  switch (paymentMethod) {
    case "pm-cash":
      return "cash";
    case "pm-wallet":
      return "points";
    default:
      return paymentMethod;
  }
};

export const FormatHelper = {
  formatAmount,
  formatPaymentMethod
};
