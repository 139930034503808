import axios from "../helper/AxiosHelper";
import { statusList, inGameGoodsTypeList, inGameGoodsSlotTypeList } from "../helper/GlobalValueHelper";

const list = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "id.DESC",
  };
  const response = await axios.get(`/inGameGoods/query?query=${JSON.stringify(query)}&fields=all`);
  const result = response.data.result;

  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        type: data.type,
        slotType: data.slot_type,
        title: data.title,
        price: parseInt(data.price),
        state: data.state,
        updatedAt: data.updated_at,
        updatedBy: data.updated_by_info ? data.updated_by_info.username : "",
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const details = async id => {
  const response = await axios.get(`inGameGoods/show/${id}?fields=all`);
  const result = response.data.result;

  return {
    id: result.id,
    type: result.type ? inGameGoodsTypeList.find(type => type.value === result.type) : "",
    slotType: result.slot_type ? inGameGoodsSlotTypeList.find(slotType => slotType.value === result.slot_type) : "",
    title: result.title,
    description: result.description,
    price: parseInt(result.price),
    state: result.state ? statusList.find(status => status.value === result.state) : "",
    createdAt: result.created_at,
    updatedAt: result.updated_at,
    createdBy: result.created_by_info ? result.created_by_info.username : "",
    updatedBy: result.updated_by_info ? result.updated_by_info.username : "",
  };
};

const create = async input => {
  const data = {
    type: input.type.value,
    slot_type: input.slotType.value,
    title: input.title,
    description: input.description,
    price: input.price,
    state: input.state ? input.state.value : undefined,
  };

  const response = await axios.post(`inGameGoods/create`, data);

  return response.data.result.insert_id;
};

const update = async (id, input) => {
  const data = {
    type: input.type.value,
    slot_type: input.slotType.value,
    title: input.title,
    description: input.description,
    price: input.price,
    state: input.state ? input.state.value : undefined,
  };

  const response = await axios.post(`inGameGoods/update/${id}`, data);
  return response.data.success;
};

const deleteData = async id => {
  const response = await axios.post(`inGameGoods/delete/${id}`);
  return response.data.success;
};

export const inGameGoodsApi = {
  list,
  details,
  create,
  update,
  deleteData,
};
