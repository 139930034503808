import AuthProvider from "../components/AuthProvider";
import DashboardLayout from "../components/DashboardLayout";
import { getUserPageName } from "../helper/GlobalValueHelper";
import Create from "../views/user/Create";
import Edit from "../views/user/Edit";
import List from "../views/user/List";

const UsersRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/users",
      element: (
        <AuthProvider page={getUserPageName()} action={"view"}>
          <List />
        </AuthProvider>
      ),
    },
    {
      path: "users/:id",
      element: (
        <AuthProvider page={getUserPageName()} action={"update"}>
          <Edit />
        </AuthProvider>
      ),
    },
    {
      path: "users/create",
      element: (
        <AuthProvider page={getUserPageName()} action={"create"}>
          <Create />
        </AuthProvider>
      ),
    },
  ],
};

export default UsersRoute;
