import { Box, Breadcrumbs, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

// helper
import { checkPermission } from "../../helper/PermissionHelper";
import { pageName } from "../../reducers/commonSlice";
import { user } from "../../reducers/userSlice";

// components
import ActionFooter from "../../components/ActionFooter";
import FullScreenLoading from "../../components/FullScreenLoading";
import FilterInfo from "../../components/filter/FilterInfo";

// api
import { FilterApi } from "../../api/FilterApi";

export default function FilterEdit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({
    id: null,
    type: {
      key: 1,
      name: "collection",
      value: "collection",
    },
    subType: "",
    tag: "",
    state: {
      key: 1,
      name: "active",
      value: "active"
    },
  });
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const [haveDeletePermission, setHaveDeletePermission] = useState(false);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);

  useEffect(() => {
    if (!id) {
      navigate(-1);
    }
    fetchData();
  }, []);

  useEffect(() => {
    const deletePermission = checkPermission(currentUser, currentPage, "delete");

    setHaveDeletePermission(deletePermission);
  }, [currentUser, currentPage]);

  const fetchData = async () => {
    try {
      setFullScreenLoading(true);
      const response = await FilterApi.details(id);
      setData(response);
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const validateForm = () => {
    let error = false;

    if (data.type.length == 0) {
      toast.error(t("error.type_is_required"));
      error = true;
    }

    if (data.subType.length == 0) {
      toast.error(t("error.Sub_type_is_required"));
      error = true;
    }

    if (data.tag.length == 0) {
      toast.error(t("error.tag_is_required"));
      error = true;
    }

    return error;
  };

  const update = async () => {
    try {
      setFullScreenLoading(true);

      let error = validateForm();

      if (error) {
        return;
      }

      const success = await FilterApi.update(id, data);
      if (success) {
        toast.success(t("layout.updated_successfully"));
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const deleteFilter = async () => {
    try {
      setFullScreenLoading(true);
      const success = await FilterApi.deleteFilter(id);
      if (success) {
        toast.success(t("layout.deleted_successfully"));
        navigate("/filter");
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/filter"}>{t("menu.filter")}</Link>
        <Typography color="text.primary">{t("layout.edit")}</Typography>
      </Breadcrumbs>
      <Box component="form">
        <FilterInfo
          data={data}
          setData={setData}
          deleteFilter={deleteFilter}
          haveDeletePermission={haveDeletePermission}
        />
        <Box sx={{ my: 8 }} />
        <ActionFooter onSubmit={update} onCancel={onCancel} />
      </Box>
    </Container>
  );
}
